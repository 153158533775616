import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useAsync } from "react-async-hook";
import { Linking, Platform } from "react-native";

import { BookingReservationPayment, Page } from "../../components";
import { BookingStackParamList } from "../../routes/booking";
import { useStore, withRegistration, Bookings } from "../../stores";
import { Spot, VehicleData, VehicleSearch } from "../../stores/parking";
import { BookingPlan } from "../../stores/parking/booking/plan";
import PaymentOption from "../../stores/parking/payment/option";
import * as storybook from "../../storybook";

type PaymentNavigationProp = StackNavigationProp<
  BookingStackParamList,
  "BookingPayment"
>;
type PaymentRouteProp = RouteProp<BookingStackParamList, "BookingPayment">;

interface Props {
  navigation: PaymentNavigationProp;
  route: PaymentRouteProp;
}
const Payment = (props: Props) => {
  const { navigation, route } = props;
  const store = useStore();
  const { spotId, startAt, endAt, embed } = route.params || {};
  const spot = useMemo(() => new Spot(`spots/${spotId}`, {}, store), [spotId]);
  const userId = store.auth.user?.id;
  const vehicles = useAsync(async () => {
    return (await Promise.all(
      JSON.parse(route.params?.vehicles || "").map(
        async (vehicle: storybook.Vehicle) => {
          const search = new VehicleSearch(vehicle.code, vehicle.country);
          return search.start();
        }
      )
    )) as VehicleData[];
  }, [route.params?.vehicles]);
  const [paymentOption, setPaymentOption] = useState<PaymentOption>();

  const plan = useMemo(
    () =>
      userId && spot && vehicles.result
        ? new BookingPlan({
            userId: store.auth.user?.id || "",
            spot,
            startAt: moment(startAt).toDate(),
            endAt: moment(endAt).toDate(),
            vehicles: vehicles.result,
          })
        : undefined,
    [userId, spot, vehicles]
  );

  const goBack = () => {
    if (!vehicles.result || !startAt || !endAt) throw new Error("Data missing");
    if (Platform.OS === "web") {
      window.location.href = `/reservation/${spot.id}?vehicles=${route.params.vehicles}&startAt=${startAt}&endAt=${endAt}`;
    } else {
      navigation.navigate("BookingReservation", {
        spotId,
        vehicles: route.params.vehicles,
        startAt,
        endAt,
      });
    }
  };

  const getVehicleIds = async (vehicles?: VehicleData[]) => {
    if (!vehicles) throw new Error("No vehicles");
    const { parking } = store;
    const { userVehicles } = parking;

    await userVehicles.fetch();
    const vehicleIds = [];
    for (const vehicle of vehicles) {
      const found = userVehicles.docs.find(
        (found) => found.licensePlate === vehicle.licensePlate
      );

      if (found?.id) {
        console.info(`Found vehicle: ${found.id}`);
        vehicleIds.push(found.id);
      } else {
        const data = { ...vehicle, anonymous: vehicles.length > 1 };
        const vehicleDoc = await userVehicles.create(data);
        console.info(`New vehicle: ${vehicleDoc.id}`);
        if (!vehicleDoc.id) throw new Error("Vehicle not created");
        vehicleIds.push(vehicleDoc.id);
      }
    }
    return vehicleIds;
  };

  const createBooking = async () => {
    const vehicleIds = await getVehicleIds(vehicles.result);
    const bookings = new Bookings({ spotId }, store);
    const { redirect, booking, groupId } = await bookings.request({
      startAt: moment(startAt).toDate(),
      endAt: moment(endAt).toDate(),
      spot,
      vehicleIds,
      paymentOption,
    });
    if (!booking?.id && !groupId) throw new Error("Booking creation failed");
    if (Platform.OS === "web") {
      window.location.href =
        redirect ||
        (groupId
          ? `/group/${groupId}${embed ? `?embed=${embed}` : ""}`
          : `/booking/${booking?.id}${embed ? `?embed=${embed}` : ""}`);
    } else {
      if (redirect) Linking.openURL(redirect);
      else if (groupId) navigation.navigate("BookingGroup", { groupId });
      else if (booking?.id)
        navigation.navigate("BookingDetail", { bookingId: booking.id });
    }
  };

  return (
    <Page spot={spot}>
      <BookingReservationPayment
        plan={plan}
        paymentOption={paymentOption}
        onChange={setPaymentOption}
        onCancel={goBack}
        onSubmit={createBooking}
      />
    </Page>
  );
};

export default withRegistration(observer(Payment));
