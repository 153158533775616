import { VehicleColor } from "./color";
import Document from "../../general/document";

export interface VehicleData {
  id: string;
  userId: string;
  country: string;
  licensePlate: string;
  licenseRef: string;
  color?: VehicleColor;
  brand?: string;
  model?: string;
  description?: string;
}

class Vehicle extends Document<VehicleData> {
  get licensePlate() {
    return this.data.licensePlate;
  }
  get licenseRef() {
    return this.data.licenseRef;
  }
  get country() {
    return this.data.country;
  }
  get description() {
    const components = [];
    if (this.data.brand) components.push(this.data.brand);
    if (this.data.model) components.push(this.data.model);
    return components.join(" ");
  }
}

export default Vehicle;
