import firebase from "firebase/compat/app";

const Analytics = {
  setCurrentScreen: (name: string) => {
    firebase.analytics().logEvent("screen_view", {
      firebase_screen: name,
      firebase_screen_class: name,
    });
    console.debug(`Analytics: screen_view [${name}]`);
  },
  logEvent: (name: string, params: { [key: string]: any }) => {
    firebase.analytics().logEvent(name, params);
    console.debug(`Analytics: ${name} [${JSON.stringify(params)}]`);
  },
};

export { Analytics };
