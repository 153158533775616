import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import BookingCompleted from "./completed";
import { translatedError } from "../../../helpers";
import { Booking } from "../../../stores";
import { Button, Margins, Text, Title } from "../../../storybook";

const styles = StyleSheet.create({
  container: {},
  title: {
    marginBottom: Margins.regular,
  },
  row: {
    marginVertical: Margins.small,
  },
});

interface Props {
  booking: Booking;
  status?: string;
  onRetry?: () => void;
  error?: string;
  checkout?: boolean;
}
const BookingStatus = (props: Props) => {
  const { status, booking, onRetry, error, checkout } = props;
  const { t } = useTranslation();

  const renderCancelled = () => {
    return (
      <>
        <Title level={2} style={styles.title}>
          <Trans>Payment cancelled</Trans>
        </Title>
        <Text>
          <Trans>
            Your payment has been cancelled. If you're having problems please
            contact the helpdesk.
          </Trans>
        </Text>
        <Button
          title={t("Try again")}
          type="orange"
          right="arrowright"
          style={styles.row}
          onPress={onRetry}
        />
      </>
    );
  };

  const renderError = () => {
    return (
      <>
        <Title level={2} style={styles.title}>
          <Trans>Payment failed</Trans>
        </Title>
        <Text>
          {error ? <>{translatedError(error)}. </> : ""}
          <Trans>If you're having problems please contact the helpdesk.</Trans>
        </Text>
        {onRetry && (
          <Button
            title={t("Try again")}
            type="orange"
            right="arrowright"
            style={styles.row}
            onPress={onRetry}
          />
        )}
      </>
    );
  };

  const renderCompleted = () => (
    <>
      <Title level={2} style={styles.title}>
        <Trans>Payment succesful!</Trans>
      </Title>
      <BookingCompleted checkout={checkout} booking={booking} />
    </>
  );

  return (
    <View style={styles.container}>
      {status === "error"
        ? renderError()
        : status === "success"
        ? renderCompleted()
        : status === "cancel"
        ? renderCancelled()
        : undefined}
    </View>
  );
};

export default BookingStatus;
