import firebase from "firebase/compat/app";
import { initFirestorter } from "firestorter";
import { createContext, useContext } from "react";

import AuthStore from "./auth";
import HistoryStore from "./history";
import I18nStore from "./i18n";
import ParkingStore from "./parking";
import PaymentStore from "./payment";
import Config from "../config.json";

import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

export * from "./parking";

try {
  firebase.initializeApp(Config.firebase);
  initFirestorter({ firebase });
} catch (error) {
  console.error(error);
}

export * from "./auth";

export default class Store {
  i18n = new I18nStore(this);
  auth = new AuthStore(this);
  parking = new ParkingStore(this);
  payment = new PaymentStore(this);
  history = new HistoryStore(this);
}

const storeContext = createContext(new Store());
export const useStore = () => {
  const store = useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("useStore must be used within a StoreProvider.");
  }
  return store;
};
