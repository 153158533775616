import React from "react";
import { Trans } from "react-i18next";
import { Linking, StyleSheet, TouchableOpacity, View } from "react-native";
import QRCode from "react-native-qrcode-svg";

import Config from "../../../config.json";
import { Booking } from "../../../stores";
import { Margins, Text } from "../../../storybook";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  qr: {
    alignItems: "center",
    margin: Margins.regular,
  },
});

interface Props {
  booking: Booking;
}
const BookingPay = (props: Props) => {
  const { booking } = props;
  const url = `${window.location.origin}/public/pay/${booking.id}`;

  const open = async () => {
    await Linking.openURL(url);
  };

  const renderQr = () => {
    const qr = <QRCode size={200} value={url} />;
    if (Config.debug)
      return <TouchableOpacity onPress={open}>{qr}</TouchableOpacity>;
    return qr;
  };

  return (
    <View style={styles.container}>
      <Text>
        <Trans>
          Scan this QR code with your mobile phone to complete your payment
        </Trans>
      </Text>
      <View style={styles.qr}>{renderQr()}</View>
    </View>
  );
};

export default BookingPay;
