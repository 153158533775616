import { useDeepLink } from "./useDeepLink";

const AppsFlyerHandler = (props: any) => {
  const { route, navigation } = props;

  useDeepLink(navigation, route);

  return null;
};

export default AppsFlyerHandler;
