import tzlookup from "tz-lookup";

import { SpotData } from "../spot";

export function getSpotTimeZone(spotData: SpotData): string {
  const { location } = spotData;
  if (!location) return "Europe/Amsterdam";
  const timezone = tzlookup(location.latitude, location.longitude);
  if (!timezone) return "Europe/Amsterdam";
  return timezone;
}
