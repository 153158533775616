import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Platform } from "react-native";

import { BookingReservationForm, Page } from "../../components";
import { Data } from "../../components/booking/reservation/form";
import { BookingStackParamList } from "../../routes/booking";
import { useStore, withRegistration } from "../../stores";
import { Spot } from "../../stores/parking";

type ReservationNavigationProp = StackNavigationProp<
  BookingStackParamList,
  "BookingReservation"
>;
type ReservationRouteProp = RouteProp<
  BookingStackParamList,
  "BookingReservation"
>;

interface Props {
  navigation: ReservationNavigationProp;
  route: ReservationRouteProp;
}
const Reservation = (props: Props) => {
  const { navigation, route } = props;
  const store = useStore();
  const { spotId, vehicles, startAt, endAt, embed } = route.params || {};
  const spot = useMemo(() => new Spot(`spots/${spotId}`, {}, store), [spotId]);
  useEffect(() => {
    //Its needed to make sure spot is updated in BookingReservationForm
  }, [spot?.isLoaded]);
  const [value, setValue] = useState<Data>({
    vehicles: vehicles ? JSON.parse(vehicles) : [undefined],
    startAt: startAt
      ? moment(startAt).toDate()
      : moment().startOf("day").add(1, "day").add(8, "hour").toDate(),
    endAt: endAt
      ? moment(endAt).toDate()
      : moment().startOf("day").add(1, "day").add(17, "hour").toDate(),
  });
  const [loading, setLoading] = useState(false);

  const urlQuery = () => {
    const { vehicles, startAt, endAt } = value || {};

    const query: Record<string, string> = {};
    if (vehicles) {
      query.vehicles = JSON.stringify(
        vehicles.map((vehicle) => ({
          country: vehicle?.country || "NL",
          code: vehicle?.code || "",
        }))
      );
    }
    if (startAt) {
      query.startAt = startAt.toISOString();
    }
    if (endAt) {
      query.endAt = endAt.toISOString();
    }
    if (embed) {
      query.embed = embed;
    }
    return Object.keys(query)
      ?.map((key) => `${key}=${query[key]}`)
      .join("&");
  };

  useEffect(() => {
    const query = `?${urlQuery()}`;
    if (query !== decodeURI(window.location.search)) {
      const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        query;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }, [value]);

  // if no spot found redirect to home
  if (!spot.name && spot.isLoaded) {
    // @ts-ignore
    navigation.navigate("Home");
    return null;
  }

  const toPayment = () => {
    const { vehicles, startAt, endAt } = value || {};
    if (!vehicles?.length || !startAt || !endAt)
      throw new Error("Data missing");

    const vehicleString = JSON.stringify(
      vehicles.map((vehicle) => ({
        country: vehicle.country || "NL",
        code: vehicle.code,
      }))
    );
    if (Platform.OS === "web") {
      window.location.href = `/payment/${spotId}?${urlQuery()}`;
    } else {
      setLoading(true);
      navigation.navigate("BookingPayment", {
        spotId,
        vehicles: vehicleString,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
      });
    }
  };

  return (
    <Page spot={spot}>
      <BookingReservationForm
        value={value}
        spot={spot}
        onChange={setValue}
        onSubmit={toPayment}
        loading={loading}
      />
    </Page>
  );
};

export default withRegistration(observer(Reservation));
