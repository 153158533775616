import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useState, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { Page } from "../../../components";
import { AuthStackParamList } from "../../../routes/auth";
import { Spot, useStore, withAuthentication } from "../../../stores";
import {
  Button,
  Colors,
  Margins,
  Radius,
  Text,
  TextInput,
  Title,
} from "../../../storybook";

const styles = StyleSheet.create({
  text: {
    marginBottom: Margins.small,
  },
  button: {
    marginTop: Margins.regular,
  },
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
});

type EmailNavigationProp = StackNavigationProp<AuthStackParamList, "AuthEmail">;
type EmailRouteProp = RouteProp<AuthStackParamList, "AuthEmail">;

interface Props {
  navigation: EmailNavigationProp;
  route: EmailRouteProp;
}
const Email = (props: Props) => {
  const { navigation, route } = props;
  const { next, spotId } = route.params || {};
  const [email, setEmail] = useState("");
  const store = useStore();
  const { auth } = store;
  const { t } = useTranslation();

  const spot = useMemo(
    () => spotId && new Spot(`spots/${spotId}`, {}, store),
    [spotId]
  );

  const sendLink = async () => {
    try {
      await auth.sendSignInLinkToEmail({
        email,
        params: {
          spotId: spotId || "",
          next: next?.replace("embed=true", "") || "",
        },
      });
      window.localStorage.setItem("email", email);
      if (Platform.OS === "web") {
        if (route?.params) {
          const query = Object.keys(route.params)
            .map(
              (key) =>
                `${key}=${encodeURIComponent((route.params as any)[key])}`
            )
            .join("&");
          window.location.href = `/email/sent?${query}`;
        } else {
          window.location.href = `/email/sent`;
        }
      } else {
        navigation.navigate("AuthEmailSent", { next });
      }
    } catch (e) {
      // do nothing
      console.error(e);
    }
  };
  return (
    <Page spot={typeof spot === "string" ? undefined : spot}>
      <View style={{ width: "100%", marginTop: Margins.regular }}>
        <Title level={1} style={styles.text}>
          <Trans>Verify your email</Trans>
        </Title>
        <Text style={styles.text}>
          <Trans>
            Enter your email below and tap 'Next' to receive a verification
            link.
          </Trans>
        </Text>
        <TextInput
          label={t("Insert your email")}
          onChangeText={setEmail}
          value={email}
        />
        <Button
          style={styles.button}
          title={t("Next")}
          onPress={sendLink}
          loading={auth.sendSignInLinkToEmail.inProgress}
          disabled={auth.sendSignInLinkToEmail.inProgress}
        />
      </View>
    </Page>
  );
};

export default withAuthentication(observer(Email));
