import React from "react";
import { StyleSheet, View } from "react-native";

import { Countries } from "./styles";
import { Margins } from "../../constant";
import { Title } from "../typography";

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.tiny,
    paddingHorizontal: Margins.small,
    minWidth: 80,
  },
});

export interface Props {
  code?: string;
  country?: string;
}
const LicensePlateCode = (props: Props) => {
  const { code, country } = props;

  const containerStyle =
    Countries[country as keyof typeof Countries]?.code?.containerStyle ||
    Countries.default.code?.containerStyle;
  const textStyle =
    Countries[country as keyof typeof Countries]?.code?.textStyle ||
    Countries.default.code?.textStyle;
  return (
    <View style={[styles.container, containerStyle]}>
      <Title style={textStyle}>{(code || " ").toUpperCase()}</Title>
    </View>
  );
};

export default LicensePlateCode;
