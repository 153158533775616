import { observer } from "mobx-react";
import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { VehicleSearch } from "../../stores";
import {
  Vehicle,
  LicensePlateInput,
  Margins,
  TextInput,
} from "../../storybook";

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.regular,
    gap: Margins.small,
  },
});

export interface Data {
  licensePlate?: Vehicle;
  camera?: string;
}

interface Props {
  value?: Data;
  onChange?: (value: Data) => void;
  loading?: boolean;
}
const Scanner = (props: Props) => {
  const { value, onChange, loading } = props;
  const { t } = useTranslation();
  const vehicleSearch = useMemo(
    () =>
      new VehicleSearch(
        value?.licensePlate?.code,
        value?.licensePlate?.country
      ),
    []
  );

  const { result, error } = vehicleSearch;

  useEffect(() => {
    const newLicensePlate = { ...value?.licensePlate } as Vehicle;
    if (!result && error !== "Vehicle not found") newLicensePlate.vehicle = "";
    else {
      const vehicle = [];
      const { brand, model } = result || {};
      if (brand) vehicle.push(brand);
      if (model) vehicle.push(model);
      newLicensePlate.code = vehicleSearch.formattedLicensePlate;
      newLicensePlate.vehicle = vehicle.join(" ");
    }
    updateLicensePlate(newLicensePlate);
  }, [result, error]);

  useEffect(() => {
    vehicleSearch.country = value?.licensePlate?.country || "NL";
    vehicleSearch.licensePlate = value?.licensePlate?.code || "";
  }, [value?.licensePlate]);

  const updateLicensePlate = (licensePlate: Vehicle) => {
    onChange && onChange({ ...value, licensePlate });
  };

  const updateCamera = (camera: string) => {
    onChange && onChange({ ...value, camera });
  };

  const licensePlateValid = (value?.licensePlate?.code?.length || 0) > 2;
  const licensePlate = {
    ...value?.licensePlate,
    code: value?.licensePlate?.code || "",
    country: value?.licensePlate?.country || "NL",
  };

  return (
    <View style={styles.container}>
      <LicensePlateInput
        label={t("Enter license plate")}
        onChange={updateLicensePlate}
        value={licensePlate}
        state={error ? "error" : licensePlateValid ? "success" : "default"}
        disabled={loading}
      />
      <TextInput
        label={t("Enter camera ID")}
        value={value?.camera}
        onChangeText={updateCamera}
        autoCapitalize="none"
        autoCorrect={false}
        editable={!loading}
      />
    </View>
  );
};

export default observer(Scanner);
