import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { View, ViewProps } from "react-native";

import { currencyToDisplayString } from "../../helpers";
import { SpotRates } from "../../stores";
import { ListItem, Text } from "../../storybook";

interface Props extends ViewProps {
  rates: SpotRates;
}
const Rates = (props: Props) => {
  const { rates, ...otherProps } = props;
  const { hourly } = rates;
  const displayHourlyRates = currencyToDisplayString(
    hourly.currency,
    hourly.value,
    true
  );

  const displayDailyRates = currencyToDisplayString(
    hourly.currency,
    hourly.daily ?? 0,
    true
  );
  const { t } = useTranslation();

  return (
    <View {...otherProps}>
      <View style={{ flexDirection: "row" }}>
        <ListItem
          title={t("Rates and price")}
          description={
            <>
              <Text>{`${displayHourlyRates} / ${t("hour")}`}</Text>
              {hourly.daily && (
                <Text>{`${displayDailyRates} / 24 ${t("hour")}`}</Text>
              )}
            </>
          }
        />
      </View>
      <ListItem
        title={t("How to pay")}
        style={{ marginTop: 20 }}
        description={
          <Text>
            <Trans>
              After payment you have 20 minutes to leave the garage. For payment
              you use the My Way app, the payment QR codes on location or the
              tablet at the reception (depending on the methods available).
            </Trans>
          </Text>
        }
      />
    </View>
  );
};

export default Rates;
