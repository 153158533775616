import { StyleProp, TextStyle, ViewStyle } from "react-native";

import { Colors } from "../../constant";

const SpecialColors = {
  white: "#ffff",
  blue: "#087bf9",
  yellow: "#f9c232",
  red: "#b2393e",
};

interface Style {
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}
interface CountryStyle {
  country?: Style;
  code?: Style;
  licensePlate?: Style;
  countryCode?: string;
}

export const Countries: Record<string, CountryStyle> = {
  default: {
    country: {
      containerStyle: {
        backgroundColor: Colors.forest,
      },
      textStyle: {
        color: Colors.white,
      },
    },
    code: {
      containerStyle: {
        backgroundColor: Colors.white,
      },
      textStyle: {
        color: Colors.forest,
      },
    },
    licensePlate: {
      containerStyle: {
        borderColor: Colors.lightGrey,
        borderWidth: 1,
      },
    },
  },
  NL: {
    code: {
      containerStyle: {
        backgroundColor: SpecialColors.white,
      },
    },
    licensePlate: {
      containerStyle: {
        borderWidth: 0,
      },
    },
    countryCode: "NL",
  },
  BE: {
    code: {
      textStyle: {
        color: SpecialColors.white,
      },
    },
    countryCode: "B",
  },
  DE: {
    countryCode: "D",
  },
};
