import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { Page } from "../../components/general";
import { useStore, withAdmin } from "../../stores";
import {
  Margins,
  Title,
  TextInput,
  Button,
  Colors,
  Text,
} from "../../storybook";

type State = "error" | "success";
type InputState = {
  sourceAccount?: State;
  destinationAccount?: State;
  amount?: State;
};

const MoneyTransfer = () => {
  const [fromAccount, setFromAccount] = useState("");
  const [toAccount, setToAccount] = useState("acct_1FlXooLj8vYuVxnT");
  const [amount, setAmount] = useState("");
  const [state, setState] = useState<InputState>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<{
    result?: string;
    error?: string;
    transfer?: string;
  } | null>(null);

  const { t } = useTranslation();
  const { parking } = useStore();

  const isValidField = (field: string) => !field || field === "";

  const validFields = () => {
    const errors: InputState = {};

    if (isValidField(fromAccount)) {
      errors.sourceAccount = "error";
    }
    if (isValidField(toAccount)) {
      errors.destinationAccount = "error";
    }
    if (isValidField(amount)) {
      errors.amount = "error";
    }

    setState(errors);

    return Object.keys(errors).length === 0;
  };

  const handleTransfer = async () => {
    const isValid = validFields();

    if (!isValid) return;

    try {
      const { admin } = parking;
      setLoading(true);
      const response = await admin.transferFunds({
        destinationAccount: toAccount,
        sourceAccount: fromAccount,
        amount: Number(amount),
      });

      setResponse(response);
    } catch (error) {
      // @ts-ignore
      const { message = "" } = error;
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  const resetState = () => {
    setState({});
    setError(null);
  };

  return (
    <Page>
      <View style={{ gap: Margins.small, width: "100%" }}>
        <Title level={1}>
          <Trans>{t("Transfer funds")}</Trans>
        </Title>
        <TextInput
          state={state.sourceAccount}
          label={t("From connected account")}
          value={fromAccount}
          onChangeText={(text) => {
            resetState();
            setFromAccount(text);
          }}
          autoCapitalize="none"
          autoCorrect={false}
          disabled={loading}
        />
        <TextInput
          state={state.destinationAccount}
          label={t("To connected account")}
          value={toAccount}
          onChangeText={(text) => {
            resetState();
            setToAccount(text);
          }}
          autoCapitalize="none"
          autoCorrect={false}
          disabled={loading}
        />
        <TextInput
          state={state.amount}
          addon={{ before: <Text> €</Text> }}
          keyboardType="number-pad"
          label={t("Amount")}
          value={amount}
          onChangeText={(text) => {
            resetState();
            setAmount(text);
          }}
          autoCapitalize="none"
          autoCorrect={false}
          disabled={loading}
        />
        {error && (
          <Text style={[styles.spacing, { color: Colors.darkRed }]}>
            {error}
          </Text>
        )}
        {response?.result === "failed" && (
          <Text style={[styles.spacing, { color: Colors.darkRed }]}>
            {response.error}
          </Text>
        )}
        {response?.result === "success" && (
          <View
            style={{
              maxHeight: 200,
              overflow: "scroll",
            }}
          >
            <Text style={[styles.spacing, { color: Colors.darkGreen }]}>
              {`${JSON.stringify(response.transfer, null, 2)}`}
            </Text>
          </View>
        )}
        <Button
          title={t("Transfer amount")}
          right="arrowright"
          onPress={handleTransfer}
          loading={loading}
        />
      </View>
    </Page>
  );
};

export default withAdmin(MoneyTransfer);

const styles = StyleSheet.create({
  spacing: {
    marginHorizontal: Margins.large,
    marginBottom: Margins.regular,
  },
});
