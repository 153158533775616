import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";

import { Spot } from "../../stores";
import { Colors, Fonts, Icon, Margins, Text } from "../../storybook";

type PropsType = {
  spot?: Spot;
};

const SpotDetails: FC<PropsType> = (props) => {
  const { spot } = props;
  const { t } = useTranslation();

  if (!spot) return;

  const addressString = spot?.address
    ? `${spot?.address?.street}, ${spot?.address?.city}`
    : undefined;

  return (
    <View style={styles.container}>
      {spot?.address ? (
        <View style={styles.row}>
          <Icon name={"pin"} size={"tiny"} color={Colors.forest} />
          <Text style={styles.text}>{addressString}</Text>
        </View>
      ) : null}
      {spot?.rating ? (
        <View style={styles.row}>
          <Icon name={"star"} size={"tiny"} color={Colors.forest} />
          <Text style={styles.text}>{`${spot?.rating.toFixed(1)}`}</Text>
        </View>
      ) : null}
      {spot?.count ? (
        <View style={styles.row}>
          <Icon name={"vehicle"} size={"tiny"} color={Colors.forest} />
          <Text style={styles.text}>{`${spot?.count} ${t("spots")}`}</Text>
        </View>
      ) : null}
    </View>
  );
};

export default SpotDetails;

const styles = StyleSheet.create({
  container: {
    marginBottom: Margins.small,
  },
  row: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontFamily: Fonts.value,
    color: Colors.forest,
    paddingLeft: Margins.tiny,
  },
});
