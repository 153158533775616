import { observable, runInAction, IObservableValue } from "mobx";

export default class DebouncedProperty<T> {
  _value: IObservableValue<T>;
  _debouncedValue: IObservableValue<T>;
  _timerId: any;
  _timeout: number;

  constructor(initialValue: T, timeout: number) {
    this._value = observable.box(initialValue);
    this._debouncedValue = observable.box(initialValue);
    this._timerId = undefined;
    this._timeout = timeout;
  }

  get timeout(): number {
    return this._timeout;
  }

  get value(): T {
    return this._value.get();
  }

  set value(val: T) {
    runInAction(() => {
      const oldVal = this._value.get();
      if (val === oldVal) return;
      this._value.set(val);
      if (this._timerId) clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        runInAction(() => {
          this._timerId = undefined;
          this._debouncedValue.set(val);
        });
      }, this.timeout);
    });
  }

  get debouncedValue(): T {
    return this._debouncedValue.get();
  }
}
