import Store from "../..";

type PaymentOptionType = "applePay" | "card" | "ideal";

export interface PaymentOptionCard {
  brand: string;
  last4: string;
  expYear: number;
  expMonth: number;
  country: string;
  funding: string;
}

export interface PaymentOptionIdeal {
  bankName: string;
  cardName: string;
}

export interface Data {
  id: string;
  type: PaymentOptionType;
  paymentMethodId?: string;
  card?: PaymentOptionCard;
  ideal?: PaymentOptionIdeal;
}

export default class PaymentOption {
  readonly store: Store;
  private data: Data;

  constructor(data: Data, store: Store) {
    this.store = store;
    this.data = data;
  }

  get id() {
    return this.data.id;
  }

  get type() {
    return this.data.type;
  }

  get card() {
    return this.data.card;
  }

  get ideal() {
    return this.data.ideal;
  }

  get paymentMethodId() {
    return this.data.paymentMethodId;
  }

  get displayString() {
    switch (this.type) {
      case "applePay":
        return "Apple Pay";
      case "card": {
        const { card } = this;
        return `•••• •••• •••• ${card ? card.last4 : "????"}`;
      }
      case "ideal": {
        const { ideal } = this;
        const bankName = ideal?.bankName;
        switch (bankName) {
          case "abn_amro":
            return "ABN Amro";
          case "asn_bank":
            return "ASN Bank";
          case "bunq":
            return "Bunq";
          case "ing":
            return "ING";
          case "knab":
            return "Knab";
          case "rabobank":
            return "Rabobank";
          case "regiobank":
            return "Regiobank";
          case "sns_bank":
            return "SNS Bank";
          case "triodos_bank":
            return "Triodos Bank";
          case "van_lanschot":
            return "Van Lanschot";
          default:
            return "iDEAL";
        }
      }
      default:
        return "";
    }
  }

  get imageUrl() {
    switch (this.type) {
      case "applePay":
        return require("../../../../assets/images/applePay.png");
      case "card": {
        const { card } = this;
        const brand = card ? card.brand : undefined;
        switch (brand) {
          case "visa":
            return require("../../../../assets/images/visa.png");
          case "mastercard":
            return require("../../../../assets/images/mastercard.png");
          default:
            return "";
        }
      }
      case "ideal": {
        const { ideal } = this;
        const bankName = ideal?.bankName;
        switch (bankName) {
          case "abn_amro":
            return require("../../../../assets/images/banks/abn_amro.png");
          case "asn_bank":
            return require("../../../../assets/images/banks/asn_bank.png");
          case "bunq":
            return require("../../../../assets/images/banks/bunq.png");
          case "ing":
            return require("../../../../assets/images/banks/ing.png");
          case "knab":
            return require("../../../../assets/images/banks/knab.png");
          case "rabobank":
            return require("../../../../assets/images/banks/rabobank.png");
          case "regiobank":
            return require("../../../../assets/images/banks/regiobank.png");
          case "sns_bank":
            return require("../../../../assets/images/banks/sns_bank.png");
          case "triodos_bank":
            return require("../../../../assets/images/banks/triodos_bank.png");
          case "van_lanschot":
            return require("../../../../assets/images/banks/van_lanschot.png");
          default:
            return "";
        }
      }
      default:
        return "";
    }
  }
}
