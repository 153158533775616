import { useEffect } from "react";
import { Platform } from "react-native";

export const useDeepLink = (navigation: any, route: any) => {
  // get data from url
  const params = route?.params;
  const spotId = params?.spotId;

  useEffect(() => {
    if (spotId) {
      if (Platform.OS === "web") {
        window.location.href = `/public/start/${spotId}`;
      } else {
        navigation.navigate("PublicStart", { spotId });
      }
    }
  }, [spotId]);
};
