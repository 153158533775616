import React from "react";
import { View, StyleSheet } from "react-native";
import Flag from "react-native-country-flag";

const styles = StyleSheet.create({
  flag: {
    overflow: "hidden",
    alignItems: "center",
  },
});

export interface Props {
  country?: string;
  size?: number;
}
const CountryFlag = (props: Props) => {
  const size = props.size || 60;
  const style = {
    width: size,
    height: size,
    borderRadius: size / 2,
  };

  if (!props.country) return null;

  return (
    <View key={props.country} style={[styles.flag, style]} pointerEvents="none">
      <Flag isoCode={props.country} size={size} />
    </View>
  );
};

export default CountryFlag;
