import {
  observable,
  runInAction,
  onBecomeObserved,
  onBecomeUnobserved,
} from "mobx";

interface Data {
  time: number;
}

export class ClockModel {
  private observedRefCount: number = 0;
  private updateInterval: number;
  private intervalId: NodeJS.Timeout | void;
  private data = observable<Data>({ time: 0 });

  constructor(updateInterval: number = 1000) {
    this.updateInterval = updateInterval;
    this.intervalId = undefined;

    onBecomeObserved(this.data, "time", this.addObserverRef);
    onBecomeUnobserved(this.data, "time", this.releaseObserverRef);
  }

  get time(): number {
    const time = this.data.time;
    const now = Date.now();
    return Date.now() - time <= 2000 ? time : now;
  }

  get date(): Date {
    return new Date(this.time);
  }

  addObserverRef = () => {
    const res = ++this.observedRefCount;
    if (res === 1) {
      this.intervalId = setInterval(() => {
        runInAction(() => (this.data.time = Date.now()));
      }, this.updateInterval);
    }
    return res;
  };

  releaseObserverRef = () => {
    const res = --this.observedRefCount;
    if (res <= 0 && this.intervalId !== undefined) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
    return res;
  };
}

export const fastClock = new ClockModel(1000);
export const slowClock = new ClockModel(20000);
