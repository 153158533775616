import React from "react";
import { StyleSheet, View, ViewProps } from "react-native";

import LicensePlateCode from "./licenseplatecode";
import LicensePlateCountry from "./licenseplatecountry";
import { Countries } from "./styles";
import { Radius } from "../../constant";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderRadius: Radius.small,
    overflow: "hidden",
    alignSelf: "flex-start",
  },
});

export interface Props extends ViewProps {
  country?: string;
  code?: string;
}
const LicensePlate = (props: Props) => {
  const { code, country, style, ...otherProps } = props;

  const containerStyle = Countries.default.licensePlate?.containerStyle;

  return (
    <View style={[styles.container, containerStyle, style]} {...otherProps}>
      <LicensePlateCountry country={country} />
      <LicensePlateCode code={code} country={country} />
    </View>
  );
};

export default LicensePlate;
