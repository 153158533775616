import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useState, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";
import PhoneInput, { ICountry } from "react-native-international-phone-number";

import { Page } from "../../../components";
import { AuthStackParamList } from "../../../routes/auth";
import { Spot, useStore, withAuthentication } from "../../../stores";
import {
  Button,
  Colors,
  Fonts,
  Margins,
  Radius,
  Text,
  Title,
} from "../../../storybook";
import { TextStyles } from "../../../storybook/component/typography/text";

const styles = StyleSheet.create({
  text: {
    marginBottom: Margins.small,
  },
  button: {
    marginTop: Margins.regular,
  },
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
});

type SMSNavigationProp = StackNavigationProp<AuthStackParamList, "AuthSMS">;
type SMSRouteProp = RouteProp<AuthStackParamList, "AuthSMS">;

interface Props {
  navigation: SMSNavigationProp;
  route: SMSRouteProp;
}
const SMS = (props: Props) => {
  const { navigation, route } = props;
  const { next, spotId } = route.params || {};
  const [selectedCountry, setSelectedCountry] = useState<null | ICountry>(null);
  const [inputValue, setInputValue] = useState<string>("");

  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const store = useStore();
  const { auth, i18n } = store;
  const { t } = useTranslation();

  const spot = useMemo(
    () => spotId && new Spot(`spots/${spotId}`, {}, store),
    [spotId]
  );

  const handleInputValue = (phoneNumber: string) => {
    if (error) setError(undefined);
    setInputValue(phoneNumber);
  };

  const handleSelectedCountry = (country: ICountry) => {
    setSelectedCountry(country);
  };

  const sendLink = async () => {
    const phoneNumber = `${selectedCountry?.callingCode} ${inputValue}`;
    setLoading(true);

    try {
      await auth.sendSMSCode({ phoneNumber });

      window.localStorage.setItem("phoneNumber", phoneNumber);
      if (Platform.OS === "web") {
        if (route?.params) {
          const query = Object.keys(route.params)
            .map(
              (key) =>
                `${key}=${encodeURIComponent((route.params as any)[key])}`
            )
            .join("&");
          window.location.href = `/sms/confirm?${query}`;
        } else {
          window.location.href = `/sms/confirm`;
        }
      } else {
        navigation.navigate("AuthSMSConfirm", { next });
      }
    } catch (e: any) {
      // @ts-ignore
      const errorMessage = errorCodes[e?.code || ""];
      console.error(e);

      if (errorMessage) {
        setError(errorMessage);
      } else {
        setError(t("An error occurred. Please try again later"));
      }
    } finally {
      setLoading(false);
    }
  };

  const errorCodes = {
    "auth/missing-phone-number": t("Please insert a phone number"),
    "auth/invalid-phone-number": t("Please insert a valid phone number"),
    "auth/too-many-requests": t("Too many requests. Try again later"),
  };

  return (
    <Page spot={typeof spot === "string" ? undefined : spot}>
      <View
        style={{
          width: "100%",
          marginVertical: Margins.regular,
          maxWidth: 600,
        }}
      >
        <Title level={1} style={styles.text}>
          <Trans>Verify your phone number</Trans>
        </Title>
        <Text style={styles.text}>
          <Trans>
            Enter your phone number below and tap 'Send Code' to receive a
            verification SMS.
          </Trans>
        </Text>
        <PhoneInput
          defaultCountry="NL"
          language={i18n.language as any}
          value={inputValue}
          popularCountries={["NL", "BE", "DE", "FR", "GB", "IT", "ES", "PL"]}
          placeholder={t("Insert your phone number")}
          modalSearchInputPlaceholder={t("Search for a country")}
          modalNotFoundCountryMessage={t("Country not found")}
          onChangePhoneNumber={handleInputValue}
          selectedCountry={selectedCountry}
          onChangeSelectedCountry={handleSelectedCountry}
          phoneInputStyles={{
            container: {
              backgroundColor: Colors.moonMist,
              borderWidth: 1,
              borderColor: Colors.ash,
              borderRadius: Radius.small,
            },
            flagContainer: {
              borderTopLeftRadius: Radius.small,
              borderBottomLeftRadius: Radius.small,
              backgroundColor: Colors.ash,
              justifyContent: "center",
              borderColor: Colors.ash,
            },
            divider: {
              backgroundColor: Colors.forest,
            },
            callingCode: {
              fontSize: TextStyles[4].fontSize,
              fontFamily: Fonts.value,
              color: Colors.forest,
            },
            input: {
              fontFamily: Fonts.text,
              fontSize: TextStyles[4].fontSize,
              color: Colors.forest,
            },
          }}
        />
        {error && <Text style={{ color: Colors.red }}>{error}</Text>}
        <Button
          title={t("Send code")}
          centre="phone"
          onPress={sendLink}
          style={styles.button}
          loading={loading}
          disabled={loading}
        />
        <View id="recaptcha" />
      </View>
    </Page>
  );
};

export default withAuthentication(observer(SMS));
