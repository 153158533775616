import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Platform,
  TouchableOpacity,
  TouchableOpacityProps,
} from "react-native";

import { Colors, Fonts, Margins } from "../../constant";
import Flag from "../country/flag";
import CountryList, { Country } from "../country/list";
import { PopUp } from "../modal";
import Text from "../typography/text";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 8,
  },
  title: {
    marginLeft: Margins.small,
    fontFamily: Fonts.value,
    color: Colors.forest,
    paddingBottom: 5,
  },
  picker: {
    maxHeight: 600,
    minWidth: 400,
  },
});

export interface Props extends TouchableOpacityProps {
  language?: string;
  availableLanguages?: { [language: string]: { title: string; flag?: string } };
  onChange?: (language: string) => void;
}
const Language = (props: Props) => {
  const { style, language, availableLanguages, onChange, ...otherProps } =
    props;
  const [showPicker, setShowPicker] = useState(false);

  const updateLanguage = (value: Country) => {
    onChange && onChange(value.id);
    setShowPicker(false);
  };

  const renderPicker = () => {
    const picker = (
      <CountryList
        language={language}
        data={availableLanguages}
        onSelect={updateLanguage}
      />
    );
    if (Platform.OS === "web")
      return <View style={styles.picker}>{picker}</View>;
    return picker;
  };

  return (
    <>
      <TouchableOpacity
        style={[styles.container, style]}
        onPress={() => setShowPicker(true)}
        {...otherProps}
      >
        {language ? (
          <Flag
            key={language}
            country={
              (availableLanguages &&
                language &&
                availableLanguages[language].flag) ||
              language
            }
            size={32}
          />
        ) : null}
        <Text style={styles.title} level={4}>
          {language?.toUpperCase()}
        </Text>
      </TouchableOpacity>
      {showPicker && (
        <PopUp onRequestClose={() => setShowPicker(false)}>
          {renderPicker()}
        </PopUp>
      )}
    </>
  );
};

export default Language;
