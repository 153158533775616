import React, { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";

import { Colors, Radius } from "../../constant";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    overflow: "visible",
    elevation: 20,
  },
});

interface Props {
  children: React.ReactNode;
}
const Main: FunctionComponent<Props> = (props) => {
  const { children } = props;
  return <View style={styles.container}>{children}</View>;
};

export default Main;
