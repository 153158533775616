import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import {
  View,
  Platform,
  TouchableOpacity,
  Image,
  Linking,
  StyleSheet,
} from "react-native";
import { useMediaQuery } from "react-responsive";

import { Colors, Text, Radius, Margins } from "../../storybook";

const appleDownload = require("../../../assets/images/apple-download-link.svg");

export const Footer = () => {
  const { t } = useTranslation();
  const isSmallMobile = useMediaQuery({ maxWidth: 320 });

  const openLink = async (url: string) => {
    if (Platform.OS !== "web") {
      await Linking.openURL(url);
    } else {
      window.location.href = url;
    }
  };

  if (isMobile) return null;

  return (
    <View style={{ borderRadius: Radius.small, padding: 8 }}>
      <Text
        level={4}
        style={{ alignSelf: "center", marginBottom: Margins.small }}
      >
        {t("Download the My Way app now")}
      </Text>
      <View
        style={{
          flexDirection: !isSmallMobile ? "row" : "column",
          justifyContent: "center",
          width: isSmallMobile ? "100%" : "auto",
          margin: "auto",
          gap: Margins.small,
        }}
      >
        <TouchableOpacity
          onPress={() =>
            openLink(
              "https://apps.apple.com/nl/app/vicky-parkeerapp/id1455198990"
            )
          }
          style={styles.link}
        >
          <Image
            source={{
              uri: appleDownload,
            }}
            style={styles.image}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>
            openLink(
              "https://play.google.com/store/apps/details?id=one.vicky.vickypark"
            )
          }
          style={styles.link}
        >
          <Image
            source={{
              uri: "https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg",
            }}
            style={styles.image}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  link: {
    margin: "auto",
  },
  image: {
    height: 35,
    width: 120,
    borderRadius: Radius.small,
    backgroundColor: Colors.black,
  },
  footer: {
    alignItems: "center",
  },
});
