import firebase from "firebase/compat/app";
import { observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { ComponentType, FunctionComponent, useEffect } from "react";
import { Platform, View } from "react-native";

import User from "./user";
import Store, { useStore } from "..";
import { asyncAction } from "../../helpers";
import GeneralStore from "../general/store";

class AuthStore extends GeneralStore {
  private loaded = observable.box(false);
  private admin = observable.box(false);
  private _user = observable.box<User | undefined>();

  constructor(store: Store) {
    super(store);
    firebase.auth().onAuthStateChanged(this.updateUser);
  }

  get isLoaded() {
    return this.loaded.get();
  }

  get isSignedIn() {
    return !!this.user;
  }

  get isAnonymous() {
    return firebase.auth().currentUser?.isAnonymous;
  }

  get isAdmin() {
    return this.admin.get();
  }

  get user() {
    return this._user.get();
  }

  signOut = () => {
    const result = firebase.auth().signOut();
    this._user.set(undefined);
    return result;
  };

  signInAnonymously = () => {
    return firebase.auth().signInAnonymously();
  };

  signInWithFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  signInWithGoogle = () => {
    return firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        const provider = new firebase.auth.GoogleAuthProvider();
        return firebase.auth().signInWithPopup(provider);
      })
      .catch((error) => {
        console.error("Error setting persistence or signing in:", error);
      });
  };

  signInWithApple = () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    provider.setCustomParameters({
      locale: this.store.i18n.locale,
    });
    return firebase.auth().signInWithPopup(provider);
  };

  sendSignInLinkToEmail = asyncAction<
    { email: string; params?: Record<string, string> },
    void
  >(async (config) => {
    const { email, params } = config;
    const query =
      params &&
      Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join("&");

    const url = `${window.location.origin}/email/confirm${
      query ? `?${query}` : ""
    }`;
    await firebase.auth().sendSignInLinkToEmail(email, {
      url,
      handleCodeInApp: true,
    });
  });

  verifySignInLink = asyncAction<{ email: string; link: string }, void>(
    async (config) => {
      const { email, link } = config;
      if (!firebase.auth().isSignInWithEmailLink(link))
        throw new Error("Invalid link");
      await firebase.auth().signInWithEmailLink(email, link);
    }
  );

  sendSMSCode = asyncAction<{ phoneNumber: string }, void>(async (config) => {
    const { phoneNumber } = config;
    const recaptcha = new firebase.auth.RecaptchaVerifier("recaptcha", {
      size: "invisible",
    });
    const provider = new firebase.auth.PhoneAuthProvider();
    const verificationId = await provider.verifyPhoneNumber(
      phoneNumber,
      recaptcha
    );

    window.localStorage.setItem("verificationId", verificationId);
  });

  confirmSMSCode = asyncAction<{ code: string }, void>(async (config) => {
    const { code } = config;
    const verificationId = window.localStorage.getItem("verificationId");
    if (!verificationId) throw new Error("Verification ID not found");
    const credential = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      code
    );
    await firebase.auth().signInWithCredential(credential);
  });

  private updateUser = async (currentUser?: firebase.User | null) => {
    if (currentUser) {
      const user = new User(`user/${currentUser.uid}`, {}, this.store);
      const token = await currentUser.getIdTokenResult();
      runInAction(() => {
        this._user.set(user);
        this.admin.set(token.claims.admin || false);
        this.loaded.set(true);
      });
    } else {
      runInAction(() => {
        this._user.set(undefined);
        this.loaded.set(true);
      });
    }
  };
}

export default AuthStore;

export const withAuthentication = (
  Component: ComponentType<any>
): FunctionComponent<any> =>
  observer((props) => {
    const { auth } = useStore();
    const { isLoaded, isSignedIn, signInAnonymously } = auth;

    if (isLoaded) {
      if (!isSignedIn) {
        signInAnonymously();
      } else {
        return <Component {...props} />;
      }
    }
    return <View />;
  });

export const withRegistration =
  (Component: ComponentType<any>): FunctionComponent<any> =>
  (props) => {
    const { navigation, route } = props;
    const { path } = route;
    const { auth } = useStore();
    const { isLoaded, isSignedIn, isAnonymous } = auth;

    useEffect(() => {
      if (isLoaded && (!isSignedIn || isAnonymous)) {
        const params = { ...route.params, next: encodeURIComponent(path) };
        if (Platform.OS === "web") {
          const query = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");
          window.location.href = `/signin${path ? `?${query}` : ""}`;
        } else {
          navigation.navigate("Auth", {
            screen: "AuthSignIn",
            params,
          });
        }
      }
    }, [isLoaded, isSignedIn, isAnonymous]);

    if (isLoaded && (!isSignedIn || isAnonymous)) return <View />;
    return <Component {...props} />;
  };

export const withAnonymous = (
  Component: ComponentType<any>
): FunctionComponent<any> =>
  observer((props) => {
    const { auth } = useStore();
    const { isLoaded, isSignedIn, signInAnonymously, isAnonymous } = auth;

    if (isLoaded) {
      if (!isSignedIn || !isAnonymous) {
        signInAnonymously();
      } else {
        return <Component {...props} />;
      }
    }
    return <View />;
  });

export const withAdmin =
  (Component: ComponentType<any>): FunctionComponent<any> =>
  (props) => {
    const { navigation, route } = props;
    const { path } = route;
    const { auth } = useStore();
    const { isLoaded, isSignedIn, isAdmin, isAnonymous } = auth;

    if (isLoaded) {
      if (!isSignedIn || isAnonymous) {
        if (Platform.OS === "web") {
          window.location.href = `/signin${path ? `?next=${path}` : ""}`;
        } else {
          navigation.navigate("Auth", {
            screen: "AuthSignIn",
            params: { next: path },
          });
        }
      } else if (isAdmin) {
        return <Component {...props} />;
      } else {
        if (Platform.OS === "web") {
          window.location.href = "/admin/failed";
        } else {
          navigation.navigate("Admin", { screen: "AdminFailed" });
        }
      }
    }
    return <View />;
  };
