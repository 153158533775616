import firebase from "firebase/compat/app";

export const getImagePath = async (src: string) => {
  if (src.indexOf("gs://") === 0) {
    const dotIndex = src.indexOf(".");
    const startPathIndex = src.indexOf("/", 5);
    let reference;
    if (dotIndex >= 0 && startPathIndex >= 0 && startPathIndex > dotIndex) {
      reference = firebase.storage().refFromURL(src);
    } else {
      reference = firebase.storage().ref(src.replace(/^gs:\/+/, ""));
    }
    return await reference.getDownloadURL();
  }
  return src;
};
