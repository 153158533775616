import { SpotRates } from "../..";
import { calculateDuration } from "../../../../helpers";

export interface BookingCost {
  amount: number;
  currency: string;
}

export const getBookingCost = (
  rates: SpotRates,
  startAt: Date | undefined,
  endAt: Date | undefined,
  isOverdue: boolean = false
) => {
  const duration = calculateDuration(startAt, endAt);
  if (rates?.hourly) {
    const { value, daily, min } = rates.hourly;

    const minimumPayablePrice = Math.max(min ?? 0, 0.5); // if the spot has no minimum price, the minimum price is 0.50 (Stripe minimum)

    const hours = duration / (1000 * 60 * 60);
    let price;
    if (isOverdue) {
      price = 3 * hours * value;
    } else if (daily) {
      const days = Math.floor(hours / 24);
      const remaining = hours - days * 24;
      price = Math.min(remaining * value, daily) + days * daily;
    } else {
      price = hours * value;
    }

    const amount = Math.max(price, minimumPayablePrice);

    return {
      amount, // minimum amount that can be charged by Stripe is 0.50 //  minimumPayablePrice should be used for prepaid parkings
      currency: rates.hourly.currency,
    };
  }
  return undefined;
};
