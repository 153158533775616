import React, { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";

import { Colors, Margins, Radius } from "../../constant";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
  },
  content: {
    marginBottom: -59,
    backgroundColor: Colors.white,
    borderRadius: Radius.regular,
    shadowRadius: 25,
    shadowColor: Colors.black,
    shadowOpacity: 0.25,
    overflow: "visible",
    elevation: 20,
    alignItems: "center",
    paddingTop: Margins.small,
  },
  buttons: {
    flexDirection: "row",
    padding: Margins.tiny,
  },
});

interface Props {
  children: React.ReactNode;
}
const Footer: FunctionComponent<Props> = (props) => {
  const { children } = props;
  return (
    <View style={styles.container}>
      <View style={styles.content}>{children}</View>
    </View>
  );
};

export default Footer;
