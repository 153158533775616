import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { useTranslation } from "react-i18next";

import {
  BookingReservation,
  BookingPayment,
  BookingDetail,
  BookingGroup,
  BookingShare,
  BookingSharedDetail,
  BookingAccessConfirm,
} from "../screens";

export type BookingStackParamList = {
  BookingReservation: {
    spotId: string;
    vehicles: string;
    startAt?: string;
    endAt?: string;
    embed?: string;
  };
  BookingPayment: {
    spotId: string;
    vehicles: string;
    startAt: string;
    endAt: string;
    embed?: string;
  };
  BookingSharedDetail: { bookingId: string; embed?: string };
  BookingDetail: { bookingId: string; embed?: string };
  BookingGroup: { groupId: string; embed?: string };
  BookingShare: { shareId: string };
  BookingAccessConfirm: { bookingId: string };
};

const Stack = createNativeStackNavigator<BookingStackParamList>();

const BookingRoutes = () => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="BookingReservation"
        component={BookingReservation}
        options={{ title: `My Way | ${t("Start parking")}` }}
      />
      <Stack.Screen
        name="BookingPayment"
        component={BookingPayment}
        options={{ title: `My Way | ${t("Payment")}` }}
      />
      <Stack.Screen
        name="BookingDetail"
        component={BookingDetail}
        options={{ title: `My Way | ${t("Your booking")}` }}
      />
      <Stack.Screen
        name="BookingSharedDetail"
        component={BookingSharedDetail}
        options={{ title: `My Way | ${t("Shared booking")}` }}
      />
      <Stack.Screen
        name="BookingGroup"
        component={BookingGroup}
        options={{ title: `My Way | ${t("Your bookings")}` }}
      />
      <Stack.Screen
        name="BookingShare"
        component={BookingShare}
        options={{ title: `My Way | ${t("Shared booking")}` }}
      />
      <Stack.Screen
        name="BookingAccessConfirm"
        component={BookingAccessConfirm}
        options={{ title: `My Way | ${t("Confirmed, found your booking")}` }}
      />
    </Stack.Navigator>
  );
};

export default BookingRoutes;
