import firebase from "firebase/compat/app";
import { Document } from "firestorter";

import { getLicenseCountryCode } from "./helpers/getLicensePlateCountryCode";

export interface LastScannedLicenseData {
  scannedAt: firebase.firestore.Timestamp;
  licensePlate: string;
  licenseRef: string;
  country: string;
}

class LastScannedLicense extends Document<LastScannedLicenseData> {
  clearDelay = 120;

  get scannedAt() {
    if (!this.data.scannedAt) return undefined;
    return this.data.scannedAt.toDate().getTime();
  }

  get code() {
    return this.data.licensePlate;
  }

  get country() {
    if (!this.data.country) return "NL";
    return getLicenseCountryCode(this.data.country);
  }
}

export default LastScannedLicense;
