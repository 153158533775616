import { VehicleNotFoundError, normalizeBrand, normalizeModel } from "./core";
import { SearchProvider, SearchResult } from "./types";
import { VehicleColor } from "../color";

class SearchProviderNL implements SearchProvider {
  colorFromKleur(kleur: string): VehicleColor | void {
    switch (kleur.toLowerCase()) {
      case "zwart":
        return "black";
      case "wit":
        return "white";
      case "grijs":
        return "gray";
      case "zilver":
        return "silver";
      case "rood":
        return "red";
      case "blauw":
        return "blue";
      case "groen":
        return "green";
      case "bruin":
        return "brown";
      case "oranje":
        return "orange";
      case "geel":
        return "yellow";
      case "paars":
        return "purple";
      case "roze":
        return "pink";
      default:
        return undefined;
    }
  }

  validateVoertuigsoort(voertuigsoort: string): boolean {
    switch (voertuigsoort.toLowerCase()) {
      case "personenauto":
      case "bedrijfsauto":
        return true;
      default:
        return false;
    }
  }

  get name(): string {
    return "RDW";
  }

  validate(licensePlate: string): boolean {
    const comps = licensePlate.split("-");
    if (comps.length !== 3) return false;
    if (licensePlate.length !== 8) return false;
    return true;
  }

  adjustLicensePlate(licensePlate: string, hashed: boolean): string {
    const licencePlateRegexes = [
      /^([A-Z]{2})(\d{2})(\d{2})$/, // 1     XX-99-99    (since 1951)
      /^(\d{2})(\d{2})([A-Z]{2})$/, // 2     99-99-XX    (since 1965)
      /^(\d{2})([A-Z]{2})(\d{2})$/, // 3     99-XX-99    (since 1973)
      /^([A-Z]{2})(\d{2})([A-Z]{2})$/, // 4     XX-99-XX    (since 1978)
      /^([A-Z]{2})([A-Z]{2})(\d{2})$/, // 5     XX-XX-99    (since 1991)
      /^(\d{2})([A-Z]{2})([A-Z]{2})$/, // 6     99-XX-XX    (since 1999)
      /^(\d{2})([A-Z]{3})(\d{1})$/, // 7     99-XXX-9    (since 2005)
      /^(\d{1})([A-Z]{3})(\d{2})$/, // 8     9-XXX-99    (since 2009)
      /^([A-Z]{2})(\d{3})([A-Z]{1})$/, // 9     XX-999-X    (since 2006)
      /^([A-Z]{1})(\d{3})([A-Z]{2})$/, // 10    X-999-XX    (since 2008)
      /^([A-Z]{3})(\d{2})([A-Z]{1})$/, // 11    XXX-99-X    (since 2015)
      /^([A-Z]{1})(\d{2})([A-Z]{3})$/, // 12    X-99-XXX
      /^(\d{1})([A-Z]{2})(\d{3})$/, // 13    9-XX-999
      /^(\d{3})([A-Z]{2})(\d{1})$/, // 14    999-XX-9

      // likely upcoming plate patterns
      //^(\d{3})(\d{2})([A-Z]{1})$/,       //       999-99-X
      //^([A-Z]{3})(\d{2})(\d{1})$/,       //       XXX-99-9
      //^([A-Z]{3})([A-Z]{2})(\d{1})$/,    //       XXX-XX-9
    ];

    // licencePlates for diplomates should not be formatted
    const diplomateLicencePlateRegex = /^CD[ABFJNST]\d{1,3}$/; //for example: CDB1 of CDJ45

    const getRegexForPlate = (regexes: RegExp[], plate: string) =>
      regexes.find((reg) => reg.test(plate));

    const plate = licensePlate.replace(/-/g, "").toUpperCase();
    const regex = getRegexForPlate(licencePlateRegexes, plate);
    const noDashes = diplomateLicencePlateRegex.test(plate);

    let adjustedPlate =
      noDashes || !regex ? plate : plate.replace(regex, "$1-$2-$3");

    if (hashed) {
      const comps = adjustedPlate.split("-");
      let replaceString = "";
      for (let i = 0; i < comps[1].length; i++) {
        replaceString += "#";
      }
      comps[1] = comps[1].replace(comps[1], replaceString);
      adjustedPlate = comps[0] + "-" + comps[1] + "-" + comps[2];
    }
    return adjustedPlate;
  }

  async search(licensePlate: string): Promise<SearchResult> {
    const kenteken = licensePlate.replace(/-/g, "").toUpperCase(); // Normalize into "kenteken" for lookup
    const url = `https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${kenteken}`;
    const response = await fetch(url);
    const json = await response.json();
    if (json.length !== 1) {
      throw new VehicleNotFoundError(this);
    }
    const item = json[0];
    if (!this.validateVoertuigsoort(item.voertuigsoort)) {
      throw new VehicleNotFoundError(this);
    }
    const brand = normalizeBrand(item.merk);
    const model = normalizeModel(item.handelsbenaming, brand);
    const result: SearchResult = {
      licensePlate: item.kenteken,
      brand,
      model,
      country: "nl",
    };
    const color = this.colorFromKleur(item.eerste_kleur);
    if (color) {
      result.color = color;
    }
    return result;
  }
}

export default SearchProviderNL;
