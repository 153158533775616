import { RouteProp } from "@react-navigation/core";
import React from "react";
import { isMobile } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, View, Linking } from "react-native";
import QRCode from "react-native-qrcode-svg";

import Config from "../config.json";
import { RootStackParamList } from "../routes";
import { Button, Text, Title } from "../storybook/component";
import { Colors, Margins } from "../storybook/constant";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: Margins.regular,
    textAlign: "center",
    gap: Margins.regular,
    // @ts-ignore
    height: "100vh",
    backgroundColor: Colors.cream,
  },
});

type VoucherRouteProp = RouteProp<RootStackParamList, "Voucher">;

interface Props {
  route: VoucherRouteProp;
}
const Voucher = (props: Props) => {
  const { route } = props;
  const { dynamicLinks } = Config;
  const { baseUrl } = dynamicLinks;
  const code = route.params?.voucherCode;
  const { t } = useTranslation();

  const mobileUrl = `${baseUrl}/voucher?code=${code}`;

  const openInApp = async () => {
    const canOpen = await Linking.canOpenURL(mobileUrl);

    if (canOpen) {
      await Linking.openURL(mobileUrl);
    }
  };

  return (
    <View style={styles.container}>
      <Title level={2}>
        <Trans>Voucher</Trans>
      </Title>
      <Text style={{ marginBottom: Margins.regular, textAlign: "center" }}>
        <Trans>
          Scan this QR code with your mobile phone to open the voucher in the
          app
        </Trans>
      </Text>
      {isMobile ? (
        <>
          <a href={mobileUrl}>
            <QRCode
              value={mobileUrl}
              size={200}
              logo={require("../../assets/icon-round.png")}
            />
          </a>
          <Button title={t("Open in app")} onPress={openInApp} />
        </>
      ) : (
        <QRCode
          value={mobileUrl}
          size={200}
          logo={require("../../assets/icon-round.png")}
        />
      )}
    </View>
  );
};

export default Voucher;
