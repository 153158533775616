import React, { FunctionComponent } from "react";
import { Text, TextProps, StyleSheet, Dimensions } from "react-native";

import { Fonts, Colors } from "../../constant";

const styles = StyleSheet.create({
  container: {
    fontFamily: Fonts.text,
    color: Colors.forest,
  },
});

const IS_SMALL = Dimensions.get("window").width <= 340;

export const TextStyles = {
  1: {
    fontSize: IS_SMALL ? 32 : 38,
    lineHeight: IS_SMALL ? 32 : 38,
  },
  2: {
    fontSize: IS_SMALL ? 28 : 32,
    lineHeight: IS_SMALL ? 34 : 36,
  },
  3: {
    fontSize: IS_SMALL ? 20 : 22,
    lineHeight: IS_SMALL ? 25 : 28,
  },
  4: {
    fontSize: IS_SMALL ? 15 : 16,
    lineHeight: IS_SMALL ? 20 : 22,
  },
  5: {
    fontSize: 13,
    lineHeight: 17,
  },
  6: {
    fontSize: 12,
    lineHeight: 15,
  },
  7: {
    fontSize: 10,
    lineHeight: 13,
  },
};
export type Level = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface Props extends TextProps {
  level?: Level;
}

const TypographyText: FunctionComponent<Props> = (props) => {
  const { children, style, level, ...otherProps } = props;
  const size = level || 4;
  const textStyle = TextStyles[size];
  return (
    <Text style={[styles.container, textStyle, style]} {...otherProps}>
      {children}
    </Text>
  );
};

export default TypographyText;
