import { Collection } from "firestorter";
import { CollectionSource, ICollectionOptions } from "firestorter";

import Document from "./document";
import Store from "../index";

export type Source = CollectionSource;
export type Options<T> = ICollectionOptions<T>;

class StoreCollection<T extends Document<object>> extends Collection<T> {
  readonly store: Store;

  constructor(source: Source, options: Options<T>, store: Store) {
    super(source, options);
    this.store = store;
  }
}

export default StoreCollection;
