import { useStore } from "../../../stores";
import { sanitizeLicense } from "../../../stores/parking/vehicle/vehicles";
import { Vehicle } from "../../../storybook";

const useVehicleUpdater = () => {
  const store = useStore();
  const { parking } = store;
  const { userVehicles } = parking;

  const getVehicle = async (vehicle: Vehicle) => {
    if (!vehicle) return;
    await userVehicles.fetch();
    const licenseRef = sanitizeLicense(vehicle.code || "");
    const found = userVehicles.docs.find(
      (found) => found.licensePlate === licenseRef
    );

    if (found) {
      console.info(`Found vehicle: ${found.id}`);
      return found;
    }
    const data = {
      licensePlate: vehicle.code || "",
      country: vehicle.country || "",
      licenseRef,
    };
    const vehicleDoc = await userVehicles.create(data);
    console.info(`New vehicle: ${vehicleDoc.id}`);
    return vehicleDoc;
  };

  return { getVehicle };
};

export default useVehicleUpdater;
