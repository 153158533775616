import { observer } from "mobx-react";
import React from "react";
import { useAsync } from "react-async-hook";
import { View, Image, StyleSheet, ViewProps } from "react-native";
import { useMediaQuery } from "react-responsive";

import { getImagePath } from "../../helpers";
import { Spot } from "../../stores/parking";
import { Colors, Margins, Fonts, MyWayLogo } from "../../storybook";

interface Props extends ViewProps {
  spot: Spot;
}

const getPhotoUrl = (src?: string): Promise<string> => {
  return Promise.resolve(src ? getImagePath(src) : "");
};

const SpotCard = (props: Props) => {
  const { spot } = props;
  const { primaryPhoto } = spot;
  const photoUrl = useAsync(getPhotoUrl, [primaryPhoto]);
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const isLargeDesktop = useMediaQuery({ minWidth: 1200 });

  if (isLargeDesktop) return null;

  return (
    <View style={[styles.container, isMobile && { paddingHorizontal: 0 }]}>
      <Image
        source={{ uri: photoUrl.result }}
        style={isMobile ? styles.imageMobile : styles.image}
      />
      <View style={styles.overlay}>
        <MyWayLogo height={150} width={120} color={Colors.lime} />
      </View>
    </View>
  );
};

export default observer(SpotCard);

const styles = StyleSheet.create({
  container: {
    minHeight: 250,
    alignItems: "center",
    maxWidth: 900,
    width: "100%",
    marginHorizontal: "auto",
    paddingHorizontal: Margins.regular,
  },
  imageMobile: {
    flex: 1,
    width: "100%",
  },
  image: {
    flex: 1,
    width: "100%",
    // @ts-ignore
    clipPath: `polygon(0 0, 80% 0, 100% 100%, 0% 100%)`,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontFamily: Fonts.value,
    color: Colors.white,
  },
  title: {
    textAlign: "center",
    color: Colors.white,
    margin: Margins.regular,
  },
  icon: {
    marginHorizontal: Margins.small,
  },
  footer: {
    flexDirection: "row",
    alignItems: "center",
  },
});
