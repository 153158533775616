import {
  NavigationContainer,
  NavigatorScreenParams,
  useNavigationContainerRef,
  Route,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AppLoading from "expo-app-loading";
import { observer } from "mobx-react";
import React, { useRef } from "react";

import Admin, { AdminStackParamList } from "./admin";
import Auth, { AuthStackParamList } from "./auth";
import Booking, { BookingStackParamList } from "./booking";
import Public, { PublicStackParamList } from "./public";
import AppsFlyerHandler from "../components/general/appsflyer";
import { Home, Voucher } from "../screens";
import { useStore } from "../stores";

export type RootStackParamList = {
  Home: undefined;
  AppsFlyer: undefined;
  Booking: NavigatorScreenParams<BookingStackParamList>;
  Auth: NavigatorScreenParams<AuthStackParamList>;
  Public: NavigatorScreenParams<PublicStackParamList>;
  Admin: NavigatorScreenParams<AdminStackParamList>;
  Voucher: { voucherCode: string };
};

const Stack = createNativeStackNavigator<RootStackParamList>();

const config = {
  screens: {
    Home: "/",
    AppsFlyer: "omFe/:id",
    Booking: {
      screens: {
        BookingReservation: "reservation/:spotId",
        BookingPayment: "payment/:spotId",
        BookingDetail: "booking/:bookingId",
        BookingGroup: "group/:groupId",
        BookingShare: "share/:shareId",
        BookingSharedDetail: "shared-booking/:bookingId",
        BookingAccessConfirm: "access/confirm/:bookingId",
      },
    },
    Auth: {
      screens: {
        AuthSignIn: "signin",
        AuthEmail: "email",
        AuthEmailSent: "email/sent",
        AuthEmailConfirm: "email/confirm",
        AuthSMS: "sms",
        AuthSMSSent: "sms/sent",
        AuthSMSConfirm: "sms/confirm",
      },
    },
    Public: {
      screens: {
        PublicStart: "public/start/:spotId",
        PublicScannedStart: "public/scanned-start/:spotId",
        PublicStarted: "public/started/:bookingId",
        PublicAccess: "public/access/:spotId",
        PublicAccessConfirm: "public/access/confirm/:bookingId",
        PublicEnd: "public/end/:spotId",
        PublicCheckout: "public/checkout/:spotId",
        PublicConfirm: "public/confirm/:bookingId",
        PublicConfirmCheckout: "public/confirm/checkout/:bookingId",
        PublicScan: "public/scan/:bookingId",
        PublicPay: "public/pay/:bookingId",
        PublicPayCheckout: "public/checkout/pay/:bookingId",
        PublicCompleted: "public/pay/:bookingId/:action",
        PublicCompletedCheckout: "public/checkout/pay/:bookingId/:action",
      },
    },
    Admin: {
      screens: {
        AdminStart: "admin",
        AdminScanner: "admin/scanner",
        AdminMoneyTransfer: "admin/money-transfer",
        AdminFailed: "admin/failed",
        AdminGenerateVouchers: "admin/generate-vouchers",
      },
    },
    Voucher: "voucher/:voucherCode",
  },
};

const linking = {
  prefixes: [],
  config,
};

function App() {
  const navigationRef = useNavigationContainerRef();

  const routeNameRef = useRef<string>();
  const { auth, i18n } = useStore();
  const { isLoaded } = auth;

  if (!isLoaded) return <AppLoading />;

  const onChangeRoute = (route?: Route<string, any>) => {
    const { params } = route || {};
    if (!params) return;
    const { lang } = params;
    if (lang && lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }
  };

  return (
    <NavigationContainer
      linking={linking}
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
        onChangeRoute(navigationRef.getCurrentRoute());
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute()?.name;

        if (previousRouteName !== currentRouteName) {
          console.debug("Route: ", currentRouteName);
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
    >
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen
          name="Home"
          component={Home}
          options={{ title: `My Way | Home` }}
        />
        <Stack.Screen
          name="AppsFlyer"
          component={AppsFlyerHandler}
          options={{ title: `My Way` }}
        />
        <Stack.Screen name="Booking" component={Booking} />
        <Stack.Screen name="Auth" component={Auth} />
        <Stack.Screen name="Public" component={Public} />
        <Stack.Screen name="Admin" component={Admin} />
        <Stack.Screen
          name="Voucher"
          component={Voucher}
          options={{ title: `My Way | Voucher` }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default observer(App);
