import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";
import QRCode from "react-native-qrcode-svg";

import { QRCodeType } from "../../../stores/parking/booking/booking";
import { PopUp, PopUpProps, Text, Button } from "../../../storybook";

interface Props extends PopUpProps {
  qrCode: QRCodeType;
}
const QRCodeScreen = (props: Props) => {
  const { visible, onRequestClose, qrCode } = props;
  const { t } = useTranslation();

  return (
    <PopUp visible={visible} onRequestClose={onRequestClose}>
      <View style={{ width: "100%", padding: 32, alignItems: "center" }}>
        <QRCode size={280} value={qrCode.barcode} />
        <Text level={2}>
          <Trans>Pincode</Trans>: {qrCode.pin}
        </Text>

        <Button
          title={t("Dismiss")}
          style={{ paddingTop: 32 }}
          onPress={onRequestClose}
        />
      </View>
    </PopUp>
  );
};

export default QRCodeScreen;
