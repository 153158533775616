import { Document } from "firestorter";

export interface SpotLastScannedData {
  lastScanned?: string;
}

class SpotLastScanned extends Document<SpotLastScannedData> {
  get license() {
    return this.data.lastScanned ?? "";
  }
}

export default SpotLastScanned;
