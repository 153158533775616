import Constants from "expo-constants";
import { Platform } from "react-native";

import { BookingCost } from ".";
import Booking from "./booking";
import { Spot } from "..";
import Store from "../..";
import { callCloudFunction, asyncAction, Analytics } from "../../../helpers";
import Collection from "../../general/collection";
import PaymentOption from "../payment/option";

interface CreateData {
  spot: Spot;
  startAt?: Date;
  endAt?: Date;
  vehicleIds: string[];
  paymentOption?: PaymentOption;
  cost?: BookingCost;
  anonymous?: boolean;
}

interface ResponseData {
  booking?: Booking;
  groupId?: string;
  redirect?: string;
}

class Bookings extends Collection<Booking> {
  readonly userId?: string;
  constructor(where: Record<string, string>, store: Store) {
    super(
      "bookings",
      {
        createDocument: (source, options) =>
          new Booking(source, options, store),
        query: (ref) => {
          let query = ref
            // @ts-ignore
            .where("anonymous", "==", true)
            .where("closed", "==", false);
          Object.keys(where).forEach((key) => {
            query = query.where(key, "==", where[key] || "");
          });
          return query;
        },
      },
      store
    );
    this.userId = this.store.auth.user?.id;
  }

  request = asyncAction<CreateData, ResponseData>(async (data) => {
    const {
      spot,
      startAt,
      anonymous = false,
      endAt,
      vehicleIds,
      paymentOption,
      cost,
    } = data;
    const stripe = await this.store.payment.getStripe();

    let funcName = "requestDirectBookingEU";
    const funcArgs: any = {
      spotId: spot.id,
      vehicleIds,
      appVersion: `1.0.0 (web)`,
      platform: "web",
      device: Constants.platform?.web?.ua || "unknown",
      anonymous,
      ...(cost ? cost : undefined),
    };
    if (startAt && endAt) {
      let callbackUrl = undefined;
      if (vehicleIds.length > 1) {
        funcName = "requestGroupBookingEU";
        if (Platform.OS === "web")
          callbackUrl = `${window.location.origin}/group/:groupId`;
      } else {
        funcName = "requestFutureBookingEU";
        if (Platform.OS === "web")
          callbackUrl = `${window.location.origin}/booking/:bookingId`;
      }
      funcArgs.startAt = startAt.toISOString();
      funcArgs.endAt = endAt.toISOString();
      if (paymentOption && paymentOption.ideal) {
        funcArgs.ideal = {
          bankName: paymentOption.ideal.bankName,
          cardName: paymentOption.ideal.cardName,
          callbackUrl,
        };
      }
    } else if (endAt) {
      funcArgs.endAt = endAt.toISOString();
    }

    // Create booking
    const response = await callCloudFunction(funcName, funcArgs);
    const { booking, group, paymentIntent, setupIntent, fullPaymentIntent } =
      response;
    let redirect;
    if (fullPaymentIntent) {
      redirect = fullPaymentIntent;
    } else if (paymentIntent || setupIntent) {
      if (!paymentOption || !paymentOption.paymentMethodId)
        throw new Error("Payment not defined");
      const options = {
        payment_method: paymentOption.paymentMethodId,
        return_url: group
          ? `${window.location.origin}/group/${group.id}`
          : `${window.location.origin}/booking/${booking.id}`,
      };

      if (paymentIntent) {
        if (paymentOption.type === "ideal") {
          await stripe?.confirmIdealPayment(
            paymentIntent.clientSecret,
            options
          );
        } else if (paymentOption.type === "card") {
          await stripe?.confirmCardPayment(paymentIntent.clientSecret, options);
        }
        Analytics.logEvent("purchase", {
          affiliation: "vicky-app",
          coupon: booking.creditUsed,
          currency: booking.cost ? booking.cost.currency : "EUR",
          items: [spot.analyticsData],
          transaction_id: booking.id,
          tax: 0.21 * (booking.cost ? booking.cost.amount : 0),
          value: booking.cost ? booking.cost.amount : 0,
        });
      } else if (setupIntent) {
        if (paymentOption.type === "ideal") {
          await stripe?.confirmIdealSetup(setupIntent.clientSecret, options);
        } else if (paymentOption.type === "card") {
          await stripe?.confirmCardSetup(setupIntent.clientSecret, options);
        }
      }
    }
    if (group) {
      return {
        groupId: group.id,
        redirect,
      };
    }
    const bookingId = booking?.id;
    return {
      booking: new Booking(`bookings/${bookingId}`, {}, this.store),
      redirect,
    };
  });
}

export default Bookings;
