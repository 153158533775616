import { useEffect, useState, useCallback, useMemo } from "react";

import LastScannedLicensePlate from "../../../stores/parking/spot/lastScannedLicense";
import { Vehicle } from "../../../storybook";

const EMPTY_VEHICLE: Vehicle = { code: "", country: "NL" };

const useLastScannedLicensePlate = (spotId: string) => {
  const [vehicle, setVehicle] = useState<Vehicle>(EMPTY_VEHICLE);
  const [forceUpdate, setForceUpdate] = useState(false);

  const lastScannedLicensePlate = useMemo(
    () => new LastScannedLicensePlate(`lastScannedLicensePlate/${spotId}`),
    [spotId]
  );

  const updateVehicle = useCallback((newVehicle: Vehicle) => {
    setVehicle(newVehicle);
  }, []);

  const checkAndUpdateVehicle = () => {
    if (!lastScannedLicensePlate.scannedAt) return;

    const now = new Date().getTime();
    const diff = (now - lastScannedLicensePlate.scannedAt) / 1000;

    if (diff > lastScannedLicensePlate.clearDelay) {
      updateVehicle(EMPTY_VEHICLE);
    } else {
      updateVehicle({
        code: lastScannedLicensePlate.code,
        country: lastScannedLicensePlate.country,
      });
    }
  };

  useEffect(() => {
    if (forceUpdate) {
      checkAndUpdateVehicle();
      setForceUpdate(false);
    }
  }, [forceUpdate]);

  useEffect(() => {
    checkAndUpdateVehicle();
  }, [lastScannedLicensePlate.code]);

  useEffect(() => {
    const timer = setInterval(
      checkAndUpdateVehicle,
      (lastScannedLicensePlate.clearDelay + 1) * 1000
    );
    return () => clearInterval(timer);
  }, [lastScannedLicensePlate.clearDelay]);

  return { vehicle, updateVehicle, setForceUpdate };
};

export default useLastScannedLicensePlate;
