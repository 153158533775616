import getSymbolFromCurrency from "currency-symbol-map";
import numeral from "numeral";

export function currencyCodeToSymbol(currencyCode: string) {
  return getSymbolFromCurrency(currencyCode);
}

export function currencyToDisplayString(
  currency: string,
  value: number,
  compact?: boolean
) {
  let format = "0.00";
  if (value >= 100 && compact) {
    format = "0";
  } else if (value >= 10 && compact) {
    format = "0.00";
  }
  return `${currencyCodeToSymbol(currency)} ${numeral(value).format(format)}`;
}
