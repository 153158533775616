import moment, { Moment } from "moment-timezone";

export const toMoment = (date: any, timezone?: string) => {
  let dateMoment;
  if (!date) {
    dateMoment = moment();
  } else if (typeof date === "object" && date.toDate) {
    dateMoment = moment(date.toDate());
  } else if (typeof date === "object" && date._seconds) {
    dateMoment = moment.unix(date._seconds);
  } else {
    dateMoment = moment(date);
  }
  if (timezone) return dateMoment.tz(timezone);
  return dateMoment;
};

export const dateWithTime = (
  date: Moment,
  time?: string,
  timezone?: string
) => {
  return moment.tz(
    `${date.format("Y-MM-DD")} ${time}`,
    "Y-MM-DD k:mm",
    timezone || "Europe/Amsterdam"
  );
};
