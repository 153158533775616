import moment from "moment";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import LicensePlate from "./licenseplate";
import { currencyToDisplayString } from "../../../helpers";
import { getBookingCost, Spot } from "../../../stores";
import {
  Button,
  DateRangeInput,
  Margins,
  Vehicle,
  Title,
  Colors,
  Text,
  Icon,
  ListItem,
} from "../../../storybook";
import SpotDetails from "../../general/spotDetails";

export interface Data {
  vehicles?: Vehicle[];
  startAt?: Date;
  endAt?: Date;
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginBottom: Margins.tiny,
    color: Colors.forest,
  },
  row: {
    marginVertical: Margins.small,
  },
  licenseRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  addLicense: {
    alignSelf: "flex-start",
    marginTop: -Margins.tiny,
    marginBottom: Margins.regular,
  },
  removeLicense: {
    position: "absolute",
    right: -Margins.small,
    top: Margins.tiny,
    padding: Margins.tiny,
  },
});

interface Props {
  value?: Data;
  onChange?: (value: Data) => void;
  onSubmit?: () => void;
  spot?: Spot;
  loading?: boolean;
}
const BookingForm = (props: Props) => {
  const { value, onChange, onSubmit, loading, spot } = props;
  const { t } = useTranslation();

  const updateVehicle = (index: number) => (vehicle: Vehicle) => {
    const vehicles = value?.vehicles ? [...value?.vehicles] : [];
    vehicles[index] = vehicle;
    onChange && onChange({ ...value, vehicles });
  };

  const dates: [Date, Date] | undefined = [value?.startAt, value?.endAt].every(
    (date) => date !== undefined
  )
    ? [value!.startAt!, value!.endAt!]
    : undefined;

  const updateDates = (dates: [Date, Date] | undefined) => {
    if (dates) {
      const [startAt, endAt] = dates;
      onChange && onChange({ ...value, startAt, endAt });
    }
  };

  const licensePlate = (index: number) => {
    const vehicle = value?.vehicles?.[index];
    return {
      ...vehicle,
      code: vehicle?.code || "",
      country: vehicle?.country || "NL",
    };
  };

  const addLicensePlate = () => {
    updateVehicle(value?.vehicles?.length || 0)({
      country: "NL",
    });
  };

  const removeLicensePlate = (index: number) => () => {
    if (!value?.vehicles?.length) return;
    const vehicles = [...value?.vehicles];
    vehicles?.splice(index, 1);
    onChange && onChange({ ...value, vehicles });
  };

  const renderRemoveLicensePlate = (index: number) => (
    <TouchableOpacity
      style={{ alignSelf: "flex-start", padding: 10 }}
      onPress={removeLicensePlate(index)}
    >
      <Icon name="minus" size={20} color={Colors.forest} />
    </TouchableOpacity>
  );

  const duration = (startAt: Date, endAt: Date): string => {
    const duration = moment.duration(endAt.getTime() - startAt.getTime());
    const { t } = useTranslation();

    const formatComponent = (count: number, unit: string) => {
      return t(`{{count}} ${unit}`, { count });
    };

    const components = [
      duration.days() > 0 &&
        formatComponent(
          duration.days(),
          duration.days() === 1 ? "day" : "days"
        ),
      duration.hours() > 0 &&
        formatComponent(
          duration.hours(),
          duration.hours() === 1 ? "hour" : "hours"
        ),
      duration.minutes() > 0 &&
        formatComponent(
          duration.minutes(),
          duration.minutes() === 1 ? "minute" : "minutes"
        ),
    ].filter(Boolean);

    return components.join(" and ");
  };

  const licensePlateValid = value?.vehicles?.reduce(
    (acc, vehicle) => acc && (vehicle?.code?.length || 0) > 2,
    true
  );

  const datesValid =
    value?.startAt &&
    value?.endAt &&
    value?.startAt.getTime() < value?.endAt.getTime();

  const hourly = spot?.rates.hourly;
  const displayHourlyRates = currencyToDisplayString(
    hourly?.currency ?? "EUR",
    hourly?.value ?? 0,
    true
  );

  const cost = () => {
    if (!spot || !spot.isLoaded || !value) return;
    // const { spot } = this.data;
    // const whitelists = this.availableWhitelists.map(({ whitelist }) => ({
    //   id: whitelist.id,
    //   ...whitelist.data,
    // }));
    if (value.startAt && value.endAt) {
      const rates = spot.rates;

      const cost = getBookingCost(rates, value.startAt, value.endAt);

      if (value?.vehicles && value.vehicles.length > 1) {
        return {
          ...cost,
          amount: (cost?.amount || 0) * value.vehicles.length,
        };
      }

      return cost;
    } else return undefined;
  };
  const displayBookingCost = cost()?.amount
    ? currencyToDisplayString(
        cost()?.currency ?? "EUR",
        cost()?.amount ?? 0,
        true
      )
    : undefined;

  const displayDailyRates = currencyToDisplayString(
    hourly?.currency ?? "EUR",
    hourly?.daily ?? 0,
    true
  );

  return (
    <View style={styles.container}>
      <Title level={2} style={styles.title}>
        <Trans>Book your parking spot at </Trans>
        {spot?.name}
      </Title>
      <SpotDetails spot={spot} />
      <Title style={styles.title}>
        <Trans>License plate</Trans>
      </Title>

      {(value?.vehicles || []).map((vehicle, index) => (
        <View
          style={{
            paddingBottom: 4,
            flexDirection: "row",
          }}
          key={`license-plate-${index}`}
        >
          <LicensePlate
            onChange={updateVehicle(index)}
            value={licensePlate(index)}
          />
          {index > 0 && renderRemoveLicensePlate(index)}
        </View>
      ))}

      {(value?.vehicles?.length || 0) < 10 && (
        <TouchableOpacity
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: Margins.tiny,
            gap: Margins.tiny,
          }}
          onPress={addLicensePlate}
        >
          <Icon name="plus" size={16} color={Colors.forest} />
          <Text>
            <Trans>Add license</Trans>
          </Text>
        </TouchableOpacity>
      )}

      <Title style={{ marginTop: Margins.small }}>
        <Trans>Your selected arrival and departure</Trans>
      </Title>
      <DateRangeInput
        style={styles.row}
        value={dates && dates.length ? dates : undefined}
        defaultTimes={[
          { hours: 9, minutes: 0 },
          { hours: 17, minutes: 0 },
        ]}
        onChange={updateDates}
        state={datesValid ? "success" : "error"}
        minDate={new Date()}
      />

      {value?.vehicles &&
        (value?.vehicles).map((vehicle) =>
          vehicle?.vehicle ? (
            <ListItem
              title={t("Found vehicle")}
              description={`${vehicle.vehicle}`}
            />
          ) : undefined
        )}

      <ListItem
        title={t("Total parking time")}
        // @ts-ignore
        description={duration(dates[0], dates[1])}
      />
      {displayBookingCost && (
        <ListItem
          title={t("Price")}
          description={<Text>{`${displayBookingCost}`}</Text>}
        />
      )}
      <ListItem
        title={t("Rates")}
        description={
          <>
            <Text>{`${displayHourlyRates} / ${t("hour")}`}</Text>
            {hourly?.daily && (
              <Text>{`${displayDailyRates} / 24 ${t("hour")}`}</Text>
            )}
          </>
        }
      />
      <Button
        title={t("Reserve now")}
        centre="calendar"
        style={styles.row}
        disabled={!datesValid || !licensePlateValid || loading}
        onPress={onSubmit}
        loading={loading}
      />
    </View>
  );
};

export default BookingForm;
