import React from "react";
import { Helmet } from "react-helmet";
import { Platform } from "react-native";

import Colors from "../../../storybook/constant/colors";

const AppsflyerScript = () => {
  if (Platform.OS !== "web") return;
  return (
    <Helmet>
      <script type="text/javascript">
        {`
        !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "fd8afe50-192a-43fc-8685-d9fc70db250c"}});
        AF('banners', 'showBanner', { bannerZIndex: 1000 })
        `}
      </script>
      <meta name="theme-color" content={Colors.cream} />
    </Helmet>
  );
};

export default AppsflyerScript;
