import React, { useState } from "react";
import {
  FlatList,
  ListRenderItemInfo,
  Pressable,
  PressableStateCallbackType,
  StyleSheet,
  TextInput,
  Platform,
  FlatListProps,
} from "react-native";

// @ts-ignore
import AllData from "./data.json";
import CountryFlag from "./flag";
import { Colors, Fonts, Margins } from "../../constant";
import { Title } from "../typography";
import { TextStyles } from "../typography/text";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    padding: Margins.small,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  last: {
    borderBottomWidth: 0,
  },
  title: {
    marginHorizontal: Margins.small,
  },
  search: {
    height: 54,
    width: "100%",
    padding: Margins.small,
    fontFamily: Fonts.value,
    fontSize: TextStyles[4].fontSize,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
    ...(Platform.OS === "web" ? { outlineWidth: 0 } : undefined),
  },
});

export interface Country {
  id: string;
  localization: { [language: string]: string };
  title?: string;
  region?: string;
  flag?: string;
}

interface CountryData extends Omit<Country, "id" | "localization"> {
  title: string;
}

export interface Search {
  placeholder?: string;
}

export interface Props
  extends Omit<FlatListProps<Country>, "data" | "renderItem"> {
  language?: string;
  region?: string;
  data?: { [id: string]: CountryData };
  onSelect?: (value: Country) => void;
  search?: Search;
  preferred?: string[];
}
const List = (props: Props) => {
  const { language, region, data, onSelect, search, preferred, ...otherProps } =
    props;
  const currentLanguage = language || "en";
  const [query, setQuery] = useState("");

  const getData = () => {
    const currentData = data || AllData;
    let countries = Object.keys(currentData).map((id) => ({
      id,
      title:
        // @ts-ignore FIXME
        currentData[id].title || currentData[id].localization[currentLanguage],
      // @ts-ignore FIXME
      ...currentData[id],
    }));
    if (region) {
      countries = countries.filter((country) => country.region === region);
    }

    if (query) {
      countries = countries.filter((country) =>
        country.title.toLowerCase().includes(query.toLowerCase())
      );
    }
    return countries.sort((a, b) => {
      const preferredSort =
        preferred &&
        preferred.indexOf(a.id) !== preferred.indexOf(b.id) &&
        (preferred.indexOf(a.id) < 0
          ? +1
          : preferred.indexOf(b.id) < 0
          ? -1
          : preferred.indexOf(a.id) - preferred.indexOf(b.id));
      if (preferredSort) return preferredSort;
      return a.title.localeCompare(b?.title);
    });
  };

  const rows = getData();

  const select = (country: Country) => () => {
    onSelect && onSelect(country);
  };

  const renderItem = (info: ListRenderItemInfo<Country>) => {
    const { item, index } = info;

    const style = (state: PressableStateCallbackType) => [
      styles.row,
      {
        backgroundColor: state.pressed ? Colors.lightGrey : Colors.white,
      },
      index >= rows.length - 1 ? styles.last : undefined,
    ];

    return (
      <Pressable style={style} onPress={select(item)}>
        <CountryFlag country={item.flag || item.id.toUpperCase()} size={30} />
        <Title style={styles.title}>{item.title}</Title>
      </Pressable>
    );
  };

  return (
    <>
      {search && (
        <TextInput
          style={styles.search}
          placeholder={search.placeholder}
          value={query}
          onChangeText={setQuery}
        />
      )}
      <FlatList
        data={rows}
        renderItem={renderItem}
        keyboardShouldPersistTaps="always"
        {...otherProps}
      />
    </>
  );
};

export default List;
