export type VehicleColor =
  | "white"
  | "silver"
  | "gray"
  | "black"
  | "red"
  | "blue"
  | "green"
  | "brown"
  | "yellow"
  | "orange"
  | "purple"
  | "pink";

const COLORS: { [key: string]: string } = {
  white: "#E9E9E9",
  silver: "#C0C0C0",
  gray: "#d8d8d8",
  black: "#000000",
  red: "#F05366",
  blue: "#36c5fa",
  green: "#00a468",
  brown: "#654321",
  yellow: "#ffd403",
  orange: "#ED772D",
  purple: "#942BE3",
  pink: "#DE3FF0",
};
const ARRAY = Object.keys(COLORS) as VehicleColor[];

// const COLOR_NAMES = {
//   white: t("White"),
//   silver: t("Silver"),
//   gray: t("Gray"),
//   black: t("Black"),
//   red: t("Red"),
//   blue: t("Blue"),
//   green: t("Green"),
//   brown: t("Brown"),
//   yellow: t("Yellow"),
//   orange: t("Orange"),
//   purple: t("Purple"),
//   pink: t("Pink"),
// };

class VehicleColors {
  get array(): VehicleColor[] {
    return ARRAY;
  }

  contains(color: string): boolean {
    return COLORS[color] !== undefined;
  }

  getHexValue(color: VehicleColor): string {
    return COLORS[color];
  }
}

export default VehicleColors;
