import { StripeCardElement } from "@stripe/stripe-js";

import PaymentOption, { Data as PaymentOptionData } from "./option";
import { asyncAction, callCloudFunction } from "../../../helpers";
import Document from "../../general/document";

interface Data {
  customerId: string;
  paymentOptions: {
    [key: string]: PaymentOptionData;
  };
  accountId: string;
  accountPayoutsEnabled: boolean;
}

export type { PaymentOptionCard, PaymentOptionIdeal } from "./option";

export default class PaymentProvider extends Document<Data> {
  get options() {
    const data = this.data.paymentOptions || {};
    return Object.keys(data).map(
      (id: string) => new PaymentOption({ ...data[id], id }, this.store)
    );
  }
  get defaultOptionId() {
    return this.store.auth.user?.settings?.paymentOptionId;
  }

  isSetupForPayments(includeIdeal: boolean) {
    const options = this.findPaymentOptions(includeIdeal);
    return !!this.data.customerId && options && options.length >= 0;
  }

  get hasPaymentOptions() {
    const { options } = this;
    return !!this.data.customerId && !!options && options.length > 0;
  }

  async setupPayments() {
    // Get or setup payments if no customer-id exists
    if (!this.data.customerId) {
      const funcName = "setupPaymentsEU";
      console.info(`${funcName}...`);
      const data = await callCloudFunction(funcName, {});
      console.info(`${funcName}... DONE (id: ${data.customerId})`);
    }
  }

  findPaymentOptions(includeIdeal: boolean) {
    const { options } = this;
    if (includeIdeal) {
      return options;
    }
    if (!options || !options.length || options.length === 0) {
      return undefined;
    }
    return options.filter((option) => {
      return !option.ideal;
    });
  }

  defaultPaymentOption(includeIdeal: boolean) {
    const { defaultOptionId, options } = this;
    const option = defaultOptionId
      ? (this.data.paymentOptions || {})[defaultOptionId]
      : undefined;
    if (option && (includeIdeal || !option.ideal))
      return new PaymentOption(
        { ...option, id: defaultOptionId || "" },
        this.store
      );
    if (options.length) {
      if (includeIdeal) {
        return new PaymentOption(options[0], this.store);
      } else {
        const withoutIdealOptions = options.filter((option) => {
          return !option.ideal;
        });
        return withoutIdealOptions.length
          ? new PaymentOption(withoutIdealOptions[0], this.store)
          : undefined;
      }
    } else {
      return undefined;
    }
  }

  addCard = asyncAction<{ card: StripeCardElement }, { id: string }>(
    async (config) => {
      // Create Stripe payment method
      const stripe = await this.store.payment.getStripe();
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: config.card,
      });
      if (error) throw new Error(error.message);
      const { id, card } = paymentMethod || {};

      // Create payment option
      const funcName = "createPaymentOptionEU";
      console.info(`${funcName}...`);
      const data = await callCloudFunction(funcName, {
        type: "card",
        card,
        paymentMethodId: id,
      });
      console.info(`${funcName}... DONE (${JSON.stringify(data)})`);
      return data;
    }
  );

  addIdeal = asyncAction<
    { bankName: string; cardName: string; updateId?: string },
    { id: string }
  >(async (config) => {
    // Create payment option
    const funcName = "createPaymentOptionEU";
    console.info(`${funcName} with ` + config.bankName + " " + config.cardName);
    const params = {
      type: "ideal",
      ideal: { bankName: config.bankName, cardName: config.cardName },
      updateId: config.updateId,
    };
    const data = await callCloudFunction(funcName, params);
    console.info(`${funcName}... DONE (${JSON.stringify(data)})`);
    return data;
  });

  // deletePaymentOption = asyncAction<string, void>(async (paymentOptionId) => {
  //   // Create payment option
  //   const funcName = "deletePaymentOptionEU";
  //   console.info(`${funcName}...`);
  //   const data = await callCloudFunction(funcName, {
  //     paymentOptionId,
  //   });
  //   console.info(`${funcName}... DONE (${JSON.stringify(data)})`);
  // });

  //
  // Payouts
  //

  // get payoutAccount(): PayoutAccountModel {
  //   return this._payoutAccount;
  // }
}
