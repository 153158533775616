import Document from "../general/document";

interface Data {
  paymentOptionId?: string;
}

export default class UserSettings extends Document<Data> {
  get paymentOptionId(): string {
    return this.data.paymentOptionId || "";
  }
  set paymentOptionId(val: string) {
    this.set({ paymentOptionId: val }, { merge: true });
  }
}
