import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";

import { VehicleSearch } from "../../../stores/parking";
import { Margins, LicensePlateInput, Vehicle } from "../../../storybook";

export interface Data {
  vehicles?: Vehicle[];
  startAt?: Date;
  endAt?: Date;
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginBottom: Margins.regular,
  },
  row: {
    marginVertical: Margins.small,
  },
  addLicense: {
    alignSelf: "center",
    marginTop: -Margins.regular,
  },
});

interface Props {
  value?: Vehicle;
  onChange?: (value: Vehicle) => void;
}
const LicensePlate = (props: Props) => {
  const { value, onChange } = props;
  const { t } = useTranslation();
  const vehicleSearch = useMemo(
    () => new VehicleSearch(value?.code, value?.country),
    []
  );

  const { result, error } = vehicleSearch;

  useEffect(() => {
    const newVehicle = { ...value } as Vehicle;
    if (!result && error !== "Vehicle not found") newVehicle.vehicle = "";
    else {
      const vehicle: any = [];
      const { brand, model } = result || {};
      if (brand) vehicle.push(brand);
      if (model) vehicle.push(model);
      newVehicle.code = vehicleSearch.formattedLicensePlate;
      newVehicle.vehicle = vehicle.join(" ");
    }
    updateVehicle(newVehicle);
  }, [result, error]);

  useEffect(() => {
    vehicleSearch.country = value?.country || "NL";
    vehicleSearch.licensePlate = value?.code || "";
  }, [value]);

  const updateVehicle = (vehicle: Vehicle) => {
    onChange && onChange(vehicle);
  };

  const licensePlateValid = (value?.code?.length || 0) > 2;
  const licensePlate = {
    ...value,
    code: value?.code || "",
    country: value?.country || "NL",
  };

  return (
    <LicensePlateInput
      label={t("Enter license plate")}
      onChange={updateVehicle}
      value={licensePlate}
      state={error ? "error" : licensePlateValid ? "success" : "default"}
      style={styles.row}
    />
  );
};

export default observer(LicensePlate);
