import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { observable } from "mobx";
import moment from "moment";
import numeral from "numeral";
import { initReactI18next } from "react-i18next";

import "moment/locale/nl";
import "numeral/locales/nl-nl";
import "numeral/locales/en-gb";
import "numeral/locales/fr";
import "numeral/locales/de";

import GeneralStore from "../general/store";
import { enGB, nl, fr, de } from "date-fns/locale";

class I18nStore extends GeneralStore {
  _locale = observable.box("");
  readonly locales: string[];

  constructor(store: any) {
    super(store);

    const resources = {
      "en-GB": {
        translation: require("../../../assets/locale/en/translation.json"),
      },
      "nl-NL": {
        translation: require("../../../assets/locale/nl/translation.json"),
      },
      fr: {
        translation: require("../../../assets/locale/fr/translation.json"),
      },
      de: {
        translation: require("../../../assets/locale/de/translation.json"),
      },
    };

    this.locales = Object.keys(resources);

    i18n.on("languageChanged", (lng) => {
      console.info("Language: ", lng);
      this._locale.set(lng);
      numeral.locale(lng);
      moment.locale(lng);
    });

    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        lng: this._locale.get(),
        // debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
        nsSeparator: false,
        keySeparator: false,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        resources,
        react: {
          transKeepBasicHtmlNodesFor: ["br", "strong", "i", "span"],
        },
        supportedLngs: this.locales,
      });
  }

  get locale() {
    return this._locale.get();
  }

  get language() {
    return this.locale.split("-")[0];
  }

  get dateLocale() {
    switch (this.locale) {
      case "nl-NL":
        return nl;
      case "fr":
        return fr;
      case "de":
        return de;
      default:
        return enGB;
    }
  }

  get languages() {
    return this.locales.map((language) => language.split("-")[0]);
  }

  changeLocale(locale: string) {
    return i18n.changeLanguage(locale);
  }

  changeLanguage(language: string) {
    const locale = this.locales.find(
      (locale) => locale.split("-")[0] === language
    );
    if (locale) this.changeLocale(locale);
  }
}

export default I18nStore;
