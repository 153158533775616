import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { Page } from "../../components";
import { AdminStackParamList } from "../../routes/admin";
import { withAdmin } from "../../stores";
import { Button, Margins, Title } from "../../storybook";

const styles = StyleSheet.create({
  container: {
    padding: Margins.large,
  },
  button: {
    marginVertical: Margins.regular,
  },
});

type AdminNavigationProp = StackNavigationProp<
  AdminStackParamList,
  "AdminStart"
>;

interface Props {
  navigation: AdminNavigationProp;
}
const Start = (props: Props) => {
  const { navigation } = props;
  const { t } = useTranslation();

  const scanner = () => {
    if (Platform.OS === "web") {
      window.location.href = "/admin/scanner";
    } else {
      navigation.navigate("AdminScanner");
    }
  };

  const generateVouchers = () => {
    if (Platform.OS === "web") {
      window.location.href = "/admin/generate-vouchers";
    } else {
      navigation.navigate("AdminGenerateVouchers");
    }
  };

  const stripeMoneyTransfer = () => {
    if (Platform.OS === "web") {
      window.location.href = "/admin/money-transfer";
    } else {
      navigation.navigate("AdminMoneyTransfer");
    }
  };

  return (
    <Page>
      <View style={styles.container}>
        <Title level={1}>
          <Trans>My Way Admin</Trans>
        </Title>
        <Button
          title={t("License plate scanner")}
          style={styles.button}
          onPress={scanner}
        />
        <Button
          title={t("Generate vouchers")}
          style={styles.button}
          onPress={generateVouchers}
        />
        <Button
          title={t("Stripe money transfer")}
          style={styles.button}
          onPress={stripeMoneyTransfer}
        />
      </View>
    </Page>
  );
};

export default observer(withAdmin(Start));
