import React, { useState } from "react";
import {
  View,
  useWindowDimensions,
  StyleSheet,
  ViewProps,
  LayoutChangeEvent,
} from "react-native";
import Svg, { G, Path } from "react-native-svg";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  background: {
    ...StyleSheet.absoluteFillObject,
  },
});

interface Size {
  width: number;
  height: number;
}

export interface Props extends ViewProps {}

const getLayout = (dimensions: Size) => {
  const { width, height } = dimensions;
  const size = Math.max(width, height);
  return {
    size,
    left: (width - size) / 2,
    top: (height - size) / 2,
  };
};

const Background = (props: Props) => {
  const { style, ...otherProps } = props;
  const dimensions = useWindowDimensions();
  const [layout, setLayout] = useState(getLayout(dimensions));

  const updateLayout = (event: LayoutChangeEvent) => {
    const dimensions = event.nativeEvent.layout;
    setLayout(getLayout(dimensions));
  };

  const backgroundStyle = {
    top: layout.top,
    left: layout.left,
    width: layout.size,
    height: layout.size,
  };

  return (
    <View
      style={[style, styles.container]}
      {...otherProps}
      onLayout={updateLayout}
    >
      <Svg
        width={layout.size}
        height={layout.size}
        viewBox="0 0 2778 2778"
        style={[styles.background, backgroundStyle]}
      >
        <G fill="none" fillRule="evenodd">
          <Path fill="#EEE" fillRule="nonzero" d="M0 0h2778v2778H0z" />
          <Path
            fill="#F4F4F4"
            d="M2152.954 0H2778v726.392L1526.873 2778H595.087z"
          />
        </G>
      </Svg>
    </View>
  );
};

export default Background;
