import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { currencyToDisplayString, slowClock } from "../../../helpers";
import { Booking, getBookingCost } from "../../../stores";
import { LicensePlate, ListItem, Margins } from "../../../storybook";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    marginVertical: Margins.small,
  },
  col: {
    flex: 1,
  },
  item: {
    flex: 1,
  },
  cost: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

interface Props {
  booking: Booking;
}
const BookingDirectPay = (props: Props) => {
  const { booking } = props;
  const { vehicle, displayStartDate, displayStartTime, startAt, spot } =
    booking;
  const { rates } = spot || {};

  const { t } = useTranslation();
  const endAt = slowClock.date;

  const displayEndDate = moment(endAt).format("LL");
  const displayEndTime = moment(endAt).format("LT");
  const cost = rates && getBookingCost(rates, startAt, endAt);

  const duration = () => {
    const duration = moment.duration(
      (endAt?.getTime() || 0) - (startAt?.getTime() || 0)
    );
    const components = [];
    if (duration.days() > 0)
      components.push(t("{{count}} days", { count: duration.days() }));
    if (duration.hours() > 0)
      components.push(t("{{count}} hours", { count: duration.hours() }));
    if (duration.minutes() > 0)
      components.push(t("{{count}} mins", { count: duration.minutes() }));
    return components.join(` ${t("and")} `);
  };

  return (
    <>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem
            title={t("License plate")}
            description={
              <LicensePlate
                country={vehicle?.country}
                code={vehicle?.licensePlate}
              />
            }
          />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("Found vehicle")}
            description={vehicle?.description || t("Unknown vehicle")}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem
            title={t("Start time")}
            description={`${displayStartDate}\n${displayStartTime}`}
          />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("End time")}
            description={`${displayEndDate}\n${displayEndTime}`}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.col}>
          <ListItem title={t("Total duration")} description={duration()} />
        </View>
        <View style={styles.col}>
          <ListItem
            title={t("Outstanding amount")}
            description={`${currencyToDisplayString(
              cost?.currency || "eur",
              cost?.amount || 0
            )}`}
          />
        </View>
      </View>
    </>
  );
};

export default observer(BookingDirectPay);
