import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { useTranslation } from "react-i18next";

import {
  AdminScanner,
  AdminStart,
  AdminFailed,
  AdminMoneyTransfer,
  AdminGenerateVouchers,
} from "../screens";

export type AdminStackParamList = {
  AdminStart: undefined;
  AdminScanner: undefined;
  AdminFailed: undefined;
  AdminMoneyTransfer: undefined;
  AdminGenerateVouchers: undefined;
};

const Stack = createNativeStackNavigator<AdminStackParamList>();

const AdminRoutes = () => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="AdminStart"
        component={AdminStart}
        options={{ title: `My Way | ${t("Admin")}` }}
      />
      <Stack.Screen
        name="AdminScanner"
        component={AdminScanner}
        options={{ title: `My Way | ${t("Admin")}` }}
      />
      <Stack.Screen
        name="AdminFailed"
        component={AdminFailed}
        options={{ title: `My Way | ${t("Admin")}` }}
      />
      <Stack.Screen
        name="AdminMoneyTransfer"
        component={AdminMoneyTransfer}
        options={{ title: `My Way | ${t("Admin")}` }}
      />
      <Stack.Screen
        name="AdminGenerateVouchers"
        component={AdminGenerateVouchers}
        options={{ title: `My Way | ${t("Admin")}` }}
      />
    </Stack.Navigator>
  );
};

export default AdminRoutes;
