import { callCloudFunction } from "../../../helpers";
import GeneralStore from "../../general/store";

interface ScannedLicenseData {
  license: string;
  mac: string;
  country?: string;
}

interface TransferFundsData {
  sourceAccount: string;
  destinationAccount: string;
  amount: number;
}

interface VoucherData {
  type: string;
  value: number;
  count: number;
}

class AdminStore extends GeneralStore {
  scannedLicensePlate = (data: ScannedLicenseData) => {
    return callCloudFunction("callScannedLicensePlate", data);
  };

  transferFunds = (data: TransferFundsData) => {
    return callCloudFunction("createTransferFunds", data);
  };

  generateVouchers = (data: VoucherData) => {
    return callCloudFunction("requestGenerateVouchersEU", data);
  };
}

export default AdminStore;
