import React, { FunctionComponent } from "react";
import { StyleSheet } from "react-native";

import Text, { Props as TextProps } from "./text";
import { Colors, Fonts } from "../../constant";

const styles = StyleSheet.create({
  container: {
    color: Colors.forest,
    fontFamily: Fonts.title,
  },
});

export interface Props extends TextProps {}
const TypographyTitle: FunctionComponent<Props> = (props) => {
  const { style, children, ...otherProps } = props;
  return (
    <Text style={[styles.container, style]} {...otherProps}>
      {children}
    </Text>
  );
};

export default TypographyTitle;
