import { SearchProvider } from "./types";

export class VehicleSearchProviderError extends Error {
  provider: SearchProvider;
  constructor(provider: SearchProvider, message: string) {
    super(message);
    this.name = "VehicleSearchProviderError";
    this.provider = provider;
  }
}

export class VehicleNotFoundError extends VehicleSearchProviderError {
  constructor(provider: SearchProvider, message?: string) {
    super(provider, message || "Vehicle not found");
    this.name = "VehicleNotFoundError";
  }
}

function capitalize(value: string): string {
  return value
    .trim()
    .toLowerCase()
    .split(" ")
    .map((str) => str.substring(0, 1).toUpperCase() + str.substring(1))
    .join(" ");
}

export function normalizeBrand(brand: string): string {
  return capitalize(brand);
}

export function normalizeModel(model: string, brand: string): string {
  return capitalize(
    model.toLowerCase().replace(brand.toLowerCase(), "").trim()
  );
}
