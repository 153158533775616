import licenseCountryCodes from "../../../../storybook/component/country/licenseCountryCodes.json";

export function getLicenseCountryCode(input: string | number): string {
  const normalizedInput = String(input).trim().toUpperCase();

  // Check if input is a valid country code
  if (Object.values(licenseCountryCodes).includes(normalizedInput)) {
    return normalizedInput;
  }

  // Check if input is a valid country number
  if (isValidCountryNumber(normalizedInput)) {
    return licenseCountryCodes[
      normalizedInput as keyof typeof licenseCountryCodes
    ];
  }

  // Default to 'NL' if no match found
  return "NL";
}

function isValidCountryNumber(input: string): boolean {
  return /^\d+$/.test(input) && input in licenseCountryCodes;
}
