import {
  observable,
  reaction,
  runInAction,
  onBecomeObserved,
  onBecomeUnobserved,
} from "mobx";

import {
  SearchResult,
  SearchProvider,
  getVehicleSearchProviderByCountry,
} from "./searchProviders";
import DebouncedProperty from "../../general/debouncedproperty";

interface Data {
  result: SearchResult | void;
  error?: string;
}

export function formatLicensePlate(
  country: string,
  value: string = "",
  hashed: boolean
) {
  if (!value) return value;
  const searchProvider = getVehicleSearchProviderByCountry(country);
  if (searchProvider) {
    const adjustedLicensePlate = searchProvider.adjustLicensePlate(
      value,
      hashed
    );
    return adjustedLicensePlate;
  }
  return value;
}

class VehicleSearch {
  _observedRefCount: number = 0;
  _country: DebouncedProperty<string>;
  _licensePlate: DebouncedProperty<string>;
  _data = observable<Data>({ result: undefined });
  _inProgress = observable.box(false);

  _disposer: (() => any) | void;

  constructor(licensePlate?: string, country?: string) {
    this._country = new DebouncedProperty(country || "", 200);
    this._licensePlate = new DebouncedProperty(licensePlate || "", 200);
    this._disposer = undefined;

    onBecomeObserved(this._data, "result", this.addObserverRef);
    onBecomeUnobserved(this._data, "result", this.releaseObserverRef);
    if (licensePlate && country) this._search();
  }

  get country(): string {
    return this._country.value;
  }
  set country(val: string) {
    this._country.value = val;
  }

  get licensePlate(): string {
    return this._licensePlate.value;
  }
  set licensePlate(val: string) {
    this._licensePlate.value = val;
  }
  get formattedLicensePlate(): string {
    const searchProvider = getVehicleSearchProviderByCountry(this.country);
    const formattedLicensePlate = searchProvider
      ? searchProvider.adjustLicensePlate(this.licensePlate, false)
      : undefined;
    return formattedLicensePlate || this.licensePlate;
  }

  get result(): SearchResult | void {
    return this._licensePlate.debouncedValue ? this._data.result : undefined;
  }

  get inProgress(): boolean {
    return this._inProgress.get();
  }

  get error(): string {
    return (this._licensePlate.debouncedValue && this._data.error) || "";
  }

  get provider(): SearchProvider | void {
    return getVehicleSearchProviderByCountry(this._country.debouncedValue);
  }

  addObserverRef = () => {
    const res = ++this._observedRefCount;
    if (res === 1) {
      this._disposer = reaction(
        () => {
          const country = this._country.debouncedValue;
          const licensePlate = this._licensePlate.debouncedValue;
          return country + ":" + licensePlate;
        },
        () => {
          this._search();
        }
      );
    }
  };

  releaseObserverRef = () => {
    const res = --this._observedRefCount;
    if (res <= 0 && this._disposer) {
      this._disposer();
      this._disposer = undefined;
    }
  };

  async start() {
    const country = this._country.debouncedValue;
    const licensePlate = this._licensePlate.debouncedValue;

    const searchProvider = getVehicleSearchProviderByCountry(country);
    if (!searchProvider) {
      return {
        licensePlate: this.licensePlate,
        country: this.country,
        brand: "",
        model: "",
      };
    }
    const formattedLicensePlate = searchProvider.adjustLicensePlate(
      licensePlate,
      false
    );
    try {
      const result = await searchProvider.search(licensePlate);
      return result
        ? {
            ...result,
            licensePlate: formattedLicensePlate,
            country: result.country.toUpperCase(),
          }
        : undefined;
    } catch (error) {
      const { message } = error as Error;
      if (message === "Vehicle not found") {
        return {
          licensePlate: formattedLicensePlate,
          country,
        };
      }
      throw error;
    }
  }

  async _search() {
    try {
      runInAction(() => {
        this._inProgress.set(true);
      });
      const result = await this.start();
      runInAction(() => {
        this._data.result = result;
        this._inProgress.set(false);
        this._data.error = undefined;
      });
    } catch (error) {
      const { message } = error as Error;
      runInAction(() => {
        this._data.result = undefined;
        this._inProgress.set(false);
        this._data.error = message;
      });
    }

    // const country = this._country.debouncedValue;
    // const licensePlate = this._licensePlate.debouncedValue;

    // const searchProvider = getVehicleSearchProviderByCountry(country);

    // if (!searchProvider) {
    //   runInAction(() => {
    //     this._data.result = {
    //       licensePlate: this.licensePlate,
    //       country: this.country,
    //       brand: "",
    //       model: "",
    //     };
    //     this._data.inProgress = false;
    //     this._data.error = undefined;
    //   });
    //   return;
    // }

    // const formattedLicensePlate = searchProvider.adjustLicensePlate(
    //   licensePlate,
    //   false
    // );
    // if (formattedLicensePlate) {
    //   if (!searchProvider.validate(formattedLicensePlate)) {
    //     runInAction(() => {
    //       this._data.result = undefined;
    //       this._data.inProgress = false;
    //       this._data.error = "Invalid vehicle";
    //     });
    //     return;
    //   }
    // }

    // runInAction(() => {
    //   this._data.inProgress = true;
    // });
    // try {
    //   console.log("Searching...");
    //   const result = await searchProvider.search(licensePlate);
    //   console.log("Found: ", result);
    //   if (
    //     country === this._country.debouncedValue ||
    //     licensePlate === this._licensePlate.debouncedValue
    //   ) {
    //     runInAction(() => {
    //       this._data.result = result;
    //       this._data.inProgress = false;
    //       this._data.error = undefined;
    //     });
    //   }
    // } catch (err) {
    //   const { message } = err as Error;
    //   if (
    //     country === this._country.debouncedValue ||
    //     licensePlate === this._licensePlate.debouncedValue
    //   ) {
    //     runInAction(() => {
    //       this._data.result = undefined;
    //       this._data.inProgress = false;
    //       this._data.error = message;
    //     });
    //   }
    // }
  }
}

export default VehicleSearch;
