import UserSettings from "./settings";
import Document from "../general/document";

export interface UserData {
  firstName: string;
  lastName: string;
  phone: string;
  invitation: string;
}

class User extends Document<UserData> {
  readonly settings = new UserSettings(
    `userSettings/${this.id}`,
    {},
    this.store
  );

  get firstName() {
    return this.data.firstName;
  }

  get lastName() {
    return this.data.lastName;
  }
}

export default User;
