import React from "react";
import {
  StyleSheet,
  TouchableHighlight,
  TouchableHighlightProps,
} from "react-native";

import { Colors, Icon, Radius } from "../../storybook";

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.lightOrange,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: Radius.regular,
  },
});

interface Props extends TouchableHighlightProps {}
const Back = (props: Props) => {
  const { style, ...otherProps } = props;
  return (
    <TouchableHighlight
      underlayColor={Colors.darkOrange}
      style={[styles.container, style]}
      {...otherProps}
    >
      <Icon name="left" color={Colors.white} />
    </TouchableHighlight>
  );
};

export default Back;
