import Config from "../config.json";

interface SlackOptions {
  name: string;
  type?: string;
  args?: any;
}

export const reportError = (error: Error, options: SlackOptions) => {
  if (!Config?.slack) return;
  const { webhook } = Config.slack;
  return reportMessage(error.message, options, webhook);
};

export const reportMessage = (
  message: string,
  options: SlackOptions,
  webhook?: string
) => {
  if (!webhook) return;

  const blocks = [
    {
      type: "header",
      text: {
        type: "plain_text",
        text: message,
      },
    },
    {
      type: "section",
      fields: [
        {
          type: "mrkdwn",
          text: `*Type:*\n${options.type || "Function"}`,
        },
        {
          type: "mrkdwn",
          text: `*Action:*\n${options.name}`,
        },
      ],
    },
  ];

  if (options.args && typeof options.args === "object") {
    blocks.push({
      type: "section",
      fields: Object.keys(options.args).map((key) => ({
        type: "mrkdwn",
        text: `*${key}:*\n${options.args[key]}`,
      })),
    });
  }
  const body = JSON.stringify({
    text: message,
    attachments: [
      {
        color: "#D00000",
        blocks,
      },
    ],
  });
  return fetch(webhook, {
    method: "POST",
    body,
  });
};
