import { Elements } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";
import React from "react";

import CardForm, { Props as CardFormProps } from "./cardform";
import { useStore } from "../../../stores";
import { Fonts, PopUp, PopUpProps } from "../../../storybook";

export interface Data {
  card: StripeCardElement;
}

interface Props extends PopUpProps, CardFormProps {}
const Card = (props: Props) => {
  const { visible, onRequestClose, ...otherProps } = props;
  const { payment } = useStore();

  return (
    <PopUp visible={visible} onRequestClose={onRequestClose}>
      <Elements
        stripe={payment.getStripe()}
        options={{
          appearance: {
            variables: {
              fontFamily: Fonts.text,
            },
          },
        }}
      >
        <CardForm {...otherProps} />
      </Elements>
    </PopUp>
  );
};

export default Card;
