import Store from "..";

class GeneralStore {
  readonly store: Store;
  constructor(store: Store) {
    this.store = store;
  }
}

export default GeneralStore;
