import React, { ReactNode } from "react";
import { StyleSheet, View, ViewProps } from "react-native";

import { Margins } from "../../constant";
import { Text, Title } from "../typography";

const styles = StyleSheet.create({
  title: {
    marginBottom: Margins.tiny,
  },
});

export interface Props extends ViewProps {
  title: string;
  description?: string | ReactNode;
}
const ListItem = (props: Props) => {
  const { title, description, ...otherProps } = props;

  return (
    <View {...otherProps}>
      <Title style={styles.title}>{title}</Title>
      {typeof description === "string" ? (
        <Text>{description}</Text>
      ) : description ? (
        <>{description}</>
      ) : null}
    </View>
  );
};

export default ListItem;
