import moment from "moment";
import React from "react";
import { Trans } from "react-i18next";

const DAYS_PER_DURATION = 1000 * 60 * 60 * 24;
const HOURS_PER_DURATION = 1000 * 60 * 60;
const MINUTES_PER_DURATION = 1000 * 60;
const SECONDS_PER_DURATION = 1000;

export const displayCalendarDate = (date?: Date) => moment(date).calendar();

export const displayDateTime = (date?: Date) => {
  const dateMoment = moment(date);
  return dateMoment.format("lll").replace(dateMoment.format("YYYY"), "").trim();
};

export const displayDuration = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  short = false
) => {
  const duration = calculateDuration(startDate, endDate);

  const days = Math.floor(duration / DAYS_PER_DURATION);
  const hours = Math.floor((duration % DAYS_PER_DURATION) / HOURS_PER_DURATION);
  const mins = Math.floor(
    (duration % HOURS_PER_DURATION) / MINUTES_PER_DURATION
  );
  const secs = Math.round(
    (duration % MINUTES_PER_DURATION) / SECONDS_PER_DURATION
  );
  const parts = [];
  if (days >= 1) {
    parts.push(displayDays(days, short));
  }
  if (hours >= 1) {
    parts.push(displayHours(hours, short));
  }
  if (mins >= 1) {
    parts.push(displayMinutes(mins, short));
  }
  parts.push(displaySeconds(secs, short));
  return (
    <>
      {parts.map((part, index) => (
        <span key={index}>
          {index < parts.length - 1 ? (
            <>
              {part}
              {short ? (
                " "
              ) : (
                <>
                  {" "}
                  <Trans>and</Trans>{" "}
                </>
              )}
            </>
          ) : (
            part
          )}
        </span>
      ))}
    </>
  );
};

export const calculateDuration = (
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  if (!endDate || !startDate) return 0;
  return endDate.getTime() - startDate.getTime();
};

const displayDays = (count: number, short = false) => {
  return short ? (
    <Trans count={count}>{{ count }}d</Trans>
  ) : (
    <Trans count={count}>{{ count }} days</Trans>
  );
};

const displayHours = (count: number, short = false) => {
  return short ? (
    <Trans count={count}>{{ count }}h</Trans>
  ) : (
    <Trans count={count}>{{ count }} hours</Trans>
  );
};

const displayMinutes = (count: number, short = false) => {
  return short ? (
    <Trans count={count}>{{ count }}m</Trans>
  ) : (
    <Trans count={count}>{{ count }} mins</Trans>
  );
};

const displaySeconds = (count: number, short = false) => {
  return short ? (
    <Trans count={count}>{{ count }}s</Trans>
  ) : (
    <Trans count={count}>{{ count }} seconds</Trans>
  );
};
