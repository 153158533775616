import { CompositeNavigationProp, RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { Page, VehicleForm } from "../../components";
import { callCloudFunction, reportError, translatedError } from "../../helpers";
import { RootStackParamList } from "../../routes";
import { PublicStackParamList } from "../../routes/public";
import { Bookings, Spot, useStore, withAuthentication } from "../../stores";
import { sanitizeLicense } from "../../stores/parking/vehicle/vehicles";
import {
  Button,
  ListItem,
  Margins,
  Text,
  Title,
  Vehicle,
} from "../../storybook";

type PayNavigationProp = StackNavigationProp<
  PublicStackParamList,
  "PublicAccess"
>;
type RootNavigationProp = StackNavigationProp<RootStackParamList>;
type PublicAccessRouteProp = RouteProp<PublicStackParamList, "PublicAccess">;
type CombinedNavigationProp = CompositeNavigationProp<
  PayNavigationProp,
  RootNavigationProp
>;
interface Props {
  navigation: CombinedNavigationProp;
  route: PublicAccessRouteProp;
}

interface Data {
  vehicle?: Vehicle;
}

const Access = (props: Props) => {
  const { route, navigation } = props;
  const { spotId } = route.params || {};
  const store = useStore();
  const [value, setValue] = useState<Data>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactNode>();
  const { t } = useTranslation();

  const spot = useMemo(() => new Spot(`spots/${spotId}`, {}, store), [spotId]);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(undefined), 3000);
    }
  }, [error]);

  const updateVehicle = (value: Vehicle) => setValue({ vehicle: value });

  const vehicle = value?.vehicle;
  const licensePlateValid = (vehicle?.code?.length || 0) > 2;

  const confirm = async () => {
    setLoading(true);
    let bookingId;
    try {
      if (!vehicle || !vehicle.code || !vehicle.country)
        throw new Error("Vehicle not found");

      const licenseRef = sanitizeLicense(vehicle.code);

      const foundBooking = await callCloudFunction(
        "findBookingByLicenseAndSpot",
        { spotId, licenseRef }
      );

      const { isSignedIn } = store.auth;

      if (isSignedIn && foundBooking.bookingId && !foundBooking.anonymous) {
        if (Platform.OS === "web") {
          window.location.href = `/access/confirm/${foundBooking.bookingId}`;
          return;
        } else {
          // @ts-ignore
          navigation.navigate("BookingAccessConfirm", {
            bookingId: foundBooking.bookingId,
          });
          return;
        }
      }

      // Search for a anonymous booking
      const bookings = new Bookings(
        { spotId, "vehicle.licenseRef": licenseRef },
        store
      );
      await bookings.fetch();

      const newBooking = bookings.hasDocs ? bookings.docs[0] : undefined;
      if (!newBooking) throw new Error("No booking found for this vehicle");
      bookingId = newBooking.id || "";

      if (Platform.OS === "web") {
        window.location.href = `/public/access/confirm/${bookingId}`;
      } else {
        navigation.navigate("PublicAccessConfirm", { bookingId });
      }
    } catch (error) {
      const { message } = error as Error;
      console.error("Error: ", error);
      reportError(error as Error, {
        name: "public/end",
        type: "web",
        args: { bookingId },
      });
      setError(translatedError(message));
    }
    setLoading(false);
  };

  return (
    <Page spot={spot}>
      <View>
        <Title level={2}>
          <Trans>Access parking garage</Trans>
        </Title>
        <Text style={{ marginBottom: Margins.small }}>
          <Trans>Lets find your booking.</Trans>
        </Text>
        <VehicleForm
          value={vehicle}
          onChange={updateVehicle}
          style={styles.row}
        />
        <Button
          title={error || t("Find booking")}
          type={error ? "red" : undefined}
          style={styles.row}
          disabled={!licensePlateValid || loading}
          onPress={confirm}
          loading={loading}
        />
        {vehicle?.vehicle && (
          <ListItem
            style={styles.row}
            title={t("Found vehicle")}
            description={`${vehicle?.vehicle}`}
          />
        )}
      </View>
    </Page>
  );
};

export default withAuthentication(observer(Access));

const styles = StyleSheet.create({
  row: {
    marginVertical: Margins.small,
  },
});
