import AdminStore from "./admin";
import { PaymentProvider } from "./payment";
import { Vehicles } from "./vehicle";
import { asyncAction, callCloudFunction } from "../../helpers";
import GeneralStore from "../general/store";

export * from "./booking";
export * from "./vehicle";
export * from "./spot";
export * from "./gate";

type ShareClaimData = string; // Share ID
type ShareClaimResponseData = string; // Booking ID

class ParkingStore extends GeneralStore {
  private _vehicles?: Vehicles;
  private _paymentProvider?: PaymentProvider;
  readonly admin = new AdminStore(this.store);

  get userVehicles() {
    if (!this._vehicles || this._vehicles.userId !== this.store.auth.user?.id) {
      this._vehicles = new Vehicles(this.store.auth.user?.id, this.store);
    }
    return this._vehicles;
  }

  get paymentProvider() {
    if (
      !this._paymentProvider ||
      this._paymentProvider.id !== this.store.auth.user?.id
    ) {
      this._paymentProvider = new PaymentProvider(
        `stripe/${this.store.auth.user?.id}`,
        {},
        this.store
      );
    }
    return this._paymentProvider;
  }

  claimShare = asyncAction<ShareClaimData, ShareClaimResponseData>(
    async (shareId) => {
      const { booking } = await callCloudFunction("claimShareEU", { shareId });
      return booking.id;
    }
  );
}

export default ParkingStore;
