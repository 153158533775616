// @flow
import SearchProviderNL from "./nl";
import { SearchProvider } from "./types";

export * from "./types";

const searchProviderNL = new SearchProviderNL();

export function getVehicleSearchProviderByCountry(
  country: string
): SearchProvider | void {
  switch (country.toLowerCase()) {
    case "nl":
      return searchProviderNL;
    default:
      return undefined;
  }
}
