import React from "react";
import { View, StyleSheet, ViewProps } from "react-native";

import MyWayLogoText from "./myWayLogoText";
import { Margins, Colors } from "../../constant";
import { Title } from "../typography";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    marginLeft: Margins.tiny,
  },
});

const SizeStyles = {
  tiny: {
    logo: {
      height: 46,
    },
    title: {
      fontSize: 32,
      lineHeight: 32,
    },
    tagline: {
      fontSize: 6,
    },
  },
  small: {
    logo: {
      height: 51,
    },
    title: {
      fontSize: 42,
      lineHeight: 42,
    },
    tagline: {
      fontSize: 8,
    },
  },
  default: {
    logo: {
      height: 72,
    },
    title: {
      fontSize: 58,
      lineHeight: 58,
    },
    tagline: {
      fontSize: 11,
    },
  },
  large: {
    logo: {
      height: 92,
    },
    title: {
      fontSize: 72,
      lineHeight: 76,
    },
    tagline: {
      fontSize: 13.5,
    },
  },
};

export type Size = keyof typeof SizeStyles;
export const Sizes = Object.keys(SizeStyles) as Size[];

export interface Props extends ViewProps {
  size?: Size;
  tagline?: string;
  light?: boolean;
}
const BrandingIdentity = (props: Props) => {
  const { size, tagline, light, style, ...otherProps } = props;
  const sizeStyle = SizeStyles[size || "default"];
  const textStyle = light ? { color: Colors.forest } : undefined;
  return (
    <View style={[styles.container, style]} {...otherProps}>
      <View style={styles.text}>
        {tagline && (
          <Title style={[sizeStyle.tagline, textStyle]}>{tagline}</Title>
        )}
        <MyWayLogoText height={40} width={130} color={Colors.forest} />
      </View>
    </View>
  );
};

export default BrandingIdentity;
