import { StackNavigationProp } from "@react-navigation/stack";
import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { View } from "react-native";

import { AdminScanner, Page } from "../../components";
import { Data } from "../../components/admin/scanner";
import { AdminStackParamList } from "../../routes/admin";
import { useStore, withAdmin } from "../../stores";
import { sanitizeLicense } from "../../stores/parking/vehicle/vehicles";
import { Title, ListItem, Button } from "../../storybook";

interface Result {
  message: string;
  error?: boolean;
}

type AdminNavigationProp = StackNavigationProp<
  AdminStackParamList,
  "AdminScanner"
>;

interface Props {
  navigation: AdminNavigationProp;
}
const Scanner = (props: Props) => {
  const [value, setValue] = useState<Data>();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<Result>();
  const { parking } = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (result) {
      setTimeout(() => setResult(undefined), 3000);
    }
  }, [result]);

  const scanLicensePlate = async () => {
    try {
      const { admin } = parking;
      if (!value) throw new Error("No data found");
      const { licensePlate, camera } = value;
      if (!licensePlate || !licensePlate.code || !camera)
        throw new Error("Invalid data");
      setLoading(true);
      const result = await admin.scannedLicensePlate({
        license: sanitizeLicense(licensePlate.code),
        mac: camera,
        country: licensePlate.country,
      });
      setResult({ message: result, error: result !== "Ok" });
    } catch (error) {
      const { message } = error as Error;
      setResult({ message, error: true });
    }
    setLoading(false);
  };

  return (
    <Page>
      <View style={{ flex: 1, width: "100%" }}>
        <Title level={1}>
          <Trans>License plate scanner</Trans>
        </Title>
        <AdminScanner value={value} onChange={setValue} loading={loading} />
        {value?.licensePlate?.vehicle ? (
          <ListItem
            title={t("Found vehicle")}
            description={`${value?.licensePlate?.vehicle}`}
          />
        ) : null}
        <Button
          title={result?.message || t("Simulate scan")}
          type={result?.error ? "red" : undefined}
          loading={loading}
          disabled={!value?.licensePlate || !value?.camera || loading}
          onPress={scanLicensePlate}
        />
      </View>
    </Page>
  );
};

export default withAdmin(Scanner);
