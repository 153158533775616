import {
  format as formatDate,
  getYear,
  getMonth,
  getDate,
  getHours,
  getMinutes,
} from "date-fns";
import React, { ReactNode, useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity, Platform } from "react-native";

import Calendar from "./calendar";
import InputView, { Props as InputProps } from "./input";
import TimeInput, { Time } from "./time";
import { useStore } from "../../../stores";
import { Colors, Margins } from "../../constant";
import { Button } from "../button";
import { Icon } from "../icon";
import { Alert } from "../modal";
import Text, { TextStyles } from "../typography/text";

const styles = StyleSheet.create({
  container: {},
  content: {
    flexDirection: "row",
    alignItems: "center",
    ...(Platform.OS === "web" ? { outlineStyle: "none" } : {}),
  },
  from: {
    flex: 1,
    alignItems: "flex-start",
    paddingLeft: 8,
  },
  to: {
    flex: 1,
    alignItems: "flex-start",
    paddingLeft: 8,
  },
  date: {
    fontSize: TextStyles[4].fontSize,
  },
  time: {
    fontSize: TextStyles[5].fontSize,
  },
  done: {
    marginHorizontal: Margins.regular,
    marginBottom: Margins.regular,
  },
});

export interface Props extends Omit<InputProps, "renderContent"> {
  value?: [Date, Date];
  defaultValue?: [Date, Date];
  defaultTimes?: Time[];
  time?: boolean;
  timeFormat?: string;
  dateFormat?: string;
  onChange?: (value?: [Date, Date]) => void;
  minDate?: Date;
  maxDate?: Date;
  icon?: ReactNode;
}
const DateRange = (props: Props) => {
  const { i18n } = useStore();
  const [showPicker, setShowPicker] = useState(false);
  const {
    label,
    borderColor,
    state,
    addon,
    style,
    contentStyle,
    disabled,
    value,
    defaultValue,
    defaultTimes,
    time,
    timeFormat,
    dateFormat,
    onChange,
    minDate,
    maxDate,
    icon,
    ...otherProps
  } = props;

  const getTimes = (dates?: [Date, Date]) =>
    time !== false
      ? dates?.map((date) => ({
          hours: getHours(date || new Date()),
          minutes: getMinutes(date || new Date()),
        }))
      : defaultTimes;

  const [dates, setDates] = useState(value || defaultValue);
  const [times, setTimes] = useState<Time[] | undefined>(
    getTimes(value || defaultValue)
  );
  const [endIsSelected, setEndIsSelected] = useState(
    (Array.isArray(value) ? value.length : value || defaultValue?.length || 0) >
      1
  );

  useEffect(() => {
    value && setDates(value);
  }, [value]);

  const updateDate = (value: Date) => {
    if (dates?.length && !endIsSelected) {
      const newValue: [Date, Date] =
        dates[0] > value ? [value, dates[0]] : [dates[0], value];
      setDates(newValue);
      onChange && onChange(getValue(newValue, times || defaultTimes));
      if (time === false) setShowPicker(false);
      setEndIsSelected(true);
    } else {
      setDates([value, value]);
      onChange && onChange(getValue([value, value], times || defaultTimes));
      setEndIsSelected(false);
    }
  };

  const updateTime = (value: Time[]) => {
    setTimes(value);
    onChange && onChange(getValue(dates, value));
  };

  const getValue = (dates?: Date[], times?: Time[]) =>
    dates?.map((date, index) => {
      return new Date(
        getYear(date || new Date()),
        getMonth(date || new Date()),
        getDate(date || new Date()),
        times?.length || (index < 0 && times?.[index])
          ? times[index]?.hours
          : time !== false
          ? getHours(date || new Date())
          : 0,
        times?.length || (index < 0 && times?.[index])
          ? times[index]?.minutes
          : time !== false
          ? getMinutes(date || new Date())
          : 0
      );
    }) as [Date, Date];

  const currentValue = getValue(dates, times || defaultTimes);
  const fromTime =
    time !== false && (dates?.length || 0) > 0
      ? formatDate(currentValue?.[0] || new Date(), timeFormat || "p", {
          locale: i18n.dateLocale,
        })
      : "";
  const fromDate =
    (dates?.length || 0) > 0
      ? formatDate(currentValue?.[0] || new Date(), dateFormat || "PP", {
          locale: i18n.dateLocale,
        }).replace(
          `, ${formatDate(new Date(), "yyyy", { locale: i18n.dateLocale })}`,
          ""
        )
      : "";

  const toTime =
    time !== false && (dates?.length || 0) > 1
      ? formatDate(currentValue?.[1] || new Date(), timeFormat || "p", {
          locale: i18n.dateLocale,
        })
      : "";
  const toDate =
    (dates?.length || 0) > 1
      ? formatDate(currentValue?.[1] || new Date(), dateFormat || "PP", {
          locale: i18n.dateLocale,
        }).replace(
          `, ${formatDate(new Date(), "yyyy", { locale: i18n.dateLocale })}`,
          ""
        )
      : "";
  return (
    <>
      <InputView
        {...{
          label,
          borderColor,
          state,
          addon: { ...addon, after: addon?.after || false },
          contentStyle,
        }}
        style={[styles.container, style]}
        renderContent={({ textStyle }) => (
          <TouchableOpacity
            style={[styles.content]}
            disabled={disabled}
            {...otherProps}
            onPress={() => setShowPicker(true)}
          >
            <View
              style={[
                {
                  padding: 16,
                  backgroundColor: Colors.white,
                  borderRadius: 8,
                  marginRight: 8,
                  flexDirection: "row",
                },
              ]}
            >
              {icon || (
                <View
                  style={{
                    backgroundColor: Colors.moonMist,
                    padding: 8,
                    borderRadius: 8,
                  }}
                >
                  <Icon name="arrowIn" size="regular" color={Colors.black} />
                </View>
              )}
              <View style={styles.from}>
                <Text style={[textStyle, styles.date]} numberOfLines={1}>
                  {fromDate}
                </Text>
                {time !== false && (
                  <Text style={[textStyle, styles.time]}>{fromTime}</Text>
                )}
              </View>
            </View>

            <View
              style={[
                {
                  padding: 16,
                  backgroundColor: Colors.white,
                  marginRight: 8,
                  borderRadius: 8,
                  flexDirection: "row",
                },
              ]}
            >
              {icon || (
                <View
                  style={{
                    backgroundColor: Colors.moonMist,
                    padding: 8,
                    borderRadius: 8,
                  }}
                >
                  <Icon name="arrowOut" size="regular" color={Colors.black} />
                </View>
              )}
              <View style={styles.to}>
                <Text style={[textStyle, styles.date]}>{toDate}</Text>
                {time !== false && (
                  <Text style={[textStyle, styles.time]}>{toTime}</Text>
                )}
              </View>
            </View>
          </TouchableOpacity>
        )}
      />
      {showPicker && (
        <Alert onRequestClose={() => setShowPicker(false)}>
          <>
            <Calendar
              value={dates}
              onChange={updateDate}
              range
              minDate={minDate}
              maxDate={maxDate}
            />
            {time !== false && (
              <TimeInput
                range
                onChange={updateTime}
                value={times || defaultTimes}
                dates={dates}
              />
            )}
            <Button
              title={"Done"}
              onPress={() => setShowPicker(false)}
              style={styles.done}
              disabled={!dates || dates.length < 2}
            />
          </>
        </Alert>
      )}
    </>
  );
};

export default DateRange;
