import { loadStripe, Stripe } from "@stripe/stripe-js";

import Store from "..";
import Config from "../../config.json";
import { callCloudFunction, asyncAction, reportError } from "../../helpers";
import GeneralStore from "../general/store";

interface StartCheckoutData {
  bookingId: string;
  checkout?: boolean;
}
interface StartCheckoutResult {
  paymentSession: {
    id: string;
  };
}

class PaymentStore extends GeneralStore {
  private stripe?: Stripe;
  private stripeLoading?: Promise<Stripe | null>;

  constructor(store: Store) {
    super(store);
    this.loadStripe();
  }

  private loadStripe = async () => {
    this.stripeLoading = loadStripe(Config.stripe.publishableKey);
    try {
      this.stripe = (await this.stripeLoading) || undefined;
      delete this.stripeLoading;
      return this.stripe;
    } catch (error) {
      reportError(error as Error, {
        name: "store/payment",
        type: "web",
      });
    }
  };

  getStripe = async () => {
    if (!this.stripe) {
      await (this.stripeLoading || this.loadStripe());
      if (!this.stripe) throw new Error("Payment not ready");
    }
    return this.stripe;
  };

  checkout = asyncAction<StartCheckoutData, void>(async (data) => {
    const stripe = await this.getStripe();
    const { bookingId, checkout } = data;

    const result: StartCheckoutResult = await callCloudFunction(
      "createCheckoutBookingEU",
      {
        bookingId,
        platform: "web",
        checkout,
      }
    );

    if (result) {
      const response = await stripe.redirectToCheckout({
        sessionId: result.paymentSession.id,
      });
      if (response.error) {
        throw new Error(response.error.message);
      }
    }
  });
}

export default PaymentStore;
