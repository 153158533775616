import * as React from "react";
import Svg, { Path } from "react-native-svg";

export interface Props {
  width?: number;
  height?: number;
  color?: any;
}

const myWayLogoText = (props: Props) => {
  const width = props.width || ((props.height || 100) * 109) / 100;
  const height = props.height || ((props.width || 109) * 100) / 109;
  const fillColor = props.color;
  return (
    <Svg width={width} height={height} viewBox="0 0 486.05 111.44">
      <polygon
        fill={fillColor}
        points="150.89 85.16 135.18 85.16 114.71 25.12 130.42 25.12 150.89 85.16"
      />
      <Path
        fill={fillColor}
        d="M188.38,25.12c-6.89,19.59-21.82,64.92-24.98,72.3-4.77,11.12-14.8,14.02-20.87,14.02-.33,0-.64-.01-.95-.03h-16.96v-15.99h17.77s.04,0,.04,0c1.22,0,4.58-.38,6.26-4.31,2.5-5.84,13.69-40.34,22.73-66h16.96Z"
      />
      <polygon
        fill={fillColor}
        points="448.62 85.16 432.91 85.16 412.44 25.12 428.15 25.12 448.62 85.16"
      />
      <Path
        fill={fillColor}
        d="M486.05,25.04c-6.89,19.59-21.86,65-25.02,72.38-4.77,11.12-14.8,14.02-20.87,14.02-.33,0-.64-.01-.95-.03h-16.96v-15.99h17.77s.04,0,.04,0c1.22,0,4.58-.38,6.26-4.31,2.5-5.84,13.73-40.42,22.77-66.08h16.96Z"
      />
      <rect fill={fillColor} y=".03" width="16.63" height="85.13" />
      <polygon
        fill={fillColor}
        points="33.26 .03 16.63 .03 45.63 85.16 62.26 85.16 33.26 .03"
      />
      <rect fill={fillColor} x="62.27" y=".03" width="16.63" height="85.13" />
      <polygon
        fill={fillColor}
        points="95.53 .03 78.9 .03 107.9 85.16 124.53 85.16 95.53 .03"
      />
      <rect fill={fillColor} x="226.38" width="16.63" height="85.13" />
      <polygon
        fill={fillColor}
        points="259.64 85.13 243.01 85.13 272.01 0 288.64 0 259.64 85.13"
      />
      <rect fill={fillColor} x="288.65" width="16.63" height="85.13" />
      <polygon
        fill={fillColor}
        points="321.91 85.13 305.28 85.13 334.28 0 350.91 0 321.91 85.13"
      />
      <Path
        fill={fillColor}
        d="M343.58,67.92c0-12.48,12.28-18.36,24.61-18.33l27.25-.03v-2.29c0-9.24-8.76-11.76-18.25-11.76-10.2,0-17.77,4.08-17.77,11.28h-14.41c0-16.45,12.48-23.29,32.17-23.29,21.97,0,33.61,8.4,33.61,27.61v33.97h-15.12v-21.37c0,19.81-20.05,23.05-28.09,23.05-11.76,0-24.01-6.24-24.01-18.85ZM395.67,63.24v-2.8l-20.65-.09c-5.28.24-15.6,1.32-15.6,6.96,0,6.12,7.8,8.28,13.44,8.16,8.28-.36,22.81-2.76,22.81-12.24Z"
      />
    </Svg>
  );
};

export default myWayLogoText;
