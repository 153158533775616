import { useNavigation } from "@react-navigation/core";
import { observer } from "mobx-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Linking, Platform, StyleSheet, View } from "react-native";
import { useMediaQuery } from "react-responsive";

import { Page } from "../components";
import { Footer } from "../components/general/footer";
import { useStore, withAuthentication } from "../stores";
import { Button, Colors, Margins, Radius, Text, Title } from "../storybook";

const styles = StyleSheet.create({
  title: {
    marginBottom: Margins.small,
  },
  content: {
    marginVertical: Margins.large,
  },
  button: {
    flex: 1,
  },
  buttons: {
    flex: 1,
    width: "100%",
    marginTop: Margins.large,
    flexDirection: "row",
    justifyContent: "space-between",
    gap: Margins.regular,
  },
});

const Home = () => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const { navigate } = useNavigation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const openLink = async (url: string) => {
    if (Platform.OS !== "web") {
      await Linking.openURL(url);
    } else {
      window.location.href = url;
    }
  };

  return (
    <Page>
      <View style={styles.content}>
        <Title level={1} style={styles.title}>
          <Trans>Join the My Way community</Trans>
        </Title>
        <Text>
          <Trans>
            My Way is here to help you with booking and renting out parking
            places
          </Trans>
        </Text>
        <View style={[styles.buttons, isMobile && { flexDirection: "column" }]}>
          {auth.isAnonymous ? (
            <Button
              title={t("Sign up")}
              style={styles.button}
              // @ts-ignore
              onPress={() => navigate("Auth")}
              centre="logIn"
            />
          ) : (
            <Button
              title={t("Sign out")}
              type="green"
              style={styles.button}
              onPress={auth.signOut}
              centre="logOut"
            />
          )}
          <Button
            type="outline"
            title={t("Visit the website")}
            style={[
              styles.button,
              {
                borderColor: Colors.forest,
                borderWidth: 1,
                borderRadius: Radius.small,
              },
            ]}
            onPress={() => openLink("https://myway.app")}
          />
        </View>
      </View>
      <Footer />
    </Page>
  );
};

export default withAuthentication(observer(Home));
