import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { Platform, StyleSheet } from "react-native";

import { Page, BookingReservationGroupStatus } from "../../components";
import { BookingStackParamList } from "../../routes/booking";
import { Bookings, useStore, withRegistration } from "../../stores";
import { Margins, Radius, Title } from "../../storybook";

type BookingNavigationProp = StackNavigationProp<
  BookingStackParamList,
  "BookingGroup"
>;
type BookingRouteProp = RouteProp<BookingStackParamList, "BookingGroup">;

const styles = StyleSheet.create({
  notfound: {
    margin: Margins.large,
    textAlign: "center",
  },
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
});

interface Props {
  navigation: BookingNavigationProp;
  route: BookingRouteProp;
}

const Group = (props: Props) => {
  const { route, navigation } = props;
  const store = useStore();
  const { groupId } = route.params || {};

  const bookings = useMemo(() => new Bookings({ groupId }, store), [groupId]);
  const booking = bookings.docs.length ? bookings.docs[0] : undefined;
  const { spot } = booking || {};

  if (!booking || !booking.isLoaded || !spot) {
    // @ts-ignore
    navigation.navigate("Home");
    return;
  }

  const retry = () => {
    const { vehicle, startAt, endAt, spot } = booking;
    const spotId = spot?.id || "";
    if (!vehicle || !startAt || !endAt) throw new Error("Data missing");
    if (Platform.OS === "web") {
      window.location.href = `/payment/${spot?.id}?vehicles[]=${
        vehicle.country || "NL"
      }:${
        vehicle.licensePlate
      }&country=&startAt=${startAt.toISOString()}&endAt=${endAt.toISOString()}`;
    } else {
      navigation.navigate("BookingPayment", {
        spotId,
        vehicles: JSON.stringify([
          { country: vehicle.country || "NL", code: vehicle.licensePlate },
        ]),
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
      });
    }
  };

  return (
    <Page spot={spot}>
      {!booking.hasData ? (
        <Title level={1} style={styles.notfound}>
          <Trans>Booking not found</Trans>
        </Title>
      ) : (
        <BookingReservationGroupStatus
          bookings={bookings.docs}
          onRetry={retry}
        />
      )}
    </Page>
  );
};

export default withRegistration(observer(Group));
