import React, { FunctionComponent, useEffect, useRef } from "react";
import {
  StyleSheet,
  Modal,
  ModalProps,
  TouchableHighlight,
  Platform,
  StyleProp,
  ViewStyle,
  View,
  Animated,
  Easing,
} from "react-native";
import { Portal } from "react-portal";

import { Radius } from "../../constant";

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    borderWidth: 0,
  },
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "flex-end",
  },
  backdropInner: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "#00000050",
    // @ts-ignore: position fixed not recognized
    marginTop: "-100vh",
    // @ts-ignore: position fixed not recognized
    paddingTop: "100vh",
  },
  content: {
    backgroundColor: "white",
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
});

export interface Props extends ModalProps {
  contentStyle?: StyleProp<ViewStyle>;
}
const Action: FunctionComponent<Props> = (props) => {
  const { children, contentStyle, visible, ...otherProps } = props;
  const opacity = useRef(new Animated.Value(0)).current;

  const style = Platform.OS === "web" ? { position: "fixed" } : undefined;

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: visible !== false ? 1 : 0,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.out(Easing.quad),
    }).start();
  }, [visible]);

  const content = (
    <Modal
      animationType="slide"
      presentationStyle="pageSheet"
      transparent={Platform.OS === "web"}
      // @ts-ignore: position fixed not recognized
      style={[styles.container, style]}
      visible={visible}
      {...otherProps}
    >
      {Platform.OS === "web" ? (
        <>
          <Animated.View style={[styles.backdropInner, { opacity }]} />
          <TouchableHighlight
            style={styles.backdrop}
            underlayColor="#00000030"
            onPress={props.onRequestClose}
          >
            {/*Prevent action is closed when pressed inside */}
            <TouchableHighlight>
              <View style={[styles.content, contentStyle]}>{children}</View>
            </TouchableHighlight>
          </TouchableHighlight>
        </>
      ) : (
        children
      )}
    </Modal>
  );
  return Platform.OS === "web" ? <Portal>{content}</Portal> : content;
};

export default Action;
