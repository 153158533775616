import React, { FunctionComponent } from "react";
import { View, SafeAreaView, StyleSheet, ViewProps } from "react-native";

import Background from "./background";
import { Margins } from "../../constant";
import { Identity } from "../branding";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  background: {
    ...StyleSheet.absoluteFillObject,
  },
  brand: {
    flex: 1,
  },
  content: {
    width: "100%",
    maxWidth: 600,
    flex: 1,
    paddingVertical: Margins.regular,
    justifyContent: "flex-end",
  },
});

export interface Props extends ViewProps {
  tagline?: string;
}
const Onboarding: FunctionComponent<Props> = (props) => {
  const { tagline, style, children, ...otherProps } = props;
  return (
    <SafeAreaView style={[styles.container, style]} {...otherProps}>
      <Background style={styles.background} />
      <Identity size="large" tagline={tagline} style={styles.brand} />
      {children && <View style={styles.content}>{children}</View>}
    </SafeAreaView>
  );
};

export default Onboarding;
