import React from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, StyleSheet } from "react-native";

import Option from "./option";
import { Colors, Margins, PopUp, PopUpProps } from "../../../storybook";

const styles = StyleSheet.create({
  option: {
    paddingHorizontal: Margins.regular,
  },
  separator: {
    borderTopWidth: 0.5,
    borderColor: Colors.lightGrey,
  },
});

interface Props extends PopUpProps {
  onSelect?: (name: "card" | "ideal") => void;
}
const Addpayment = (props: Props) => {
  const { onSelect, ...otherProps } = props;
  const { t } = useTranslation();

  const select = (name: "card" | "ideal") => () => {
    onSelect && onSelect(name);
  };
  return (
    <PopUp {...otherProps}>
      <TouchableOpacity onPress={select("card")}>
        <Option
          title={t("Credit or debit card")}
          image={require("../../../../assets/images/creditcard.png")}
          style={styles.option}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={select("ideal")}>
        <Option
          title={t("iDeal")}
          image={require("../../../../assets/images/ideal.png")}
          style={[styles.option, styles.separator]}
        />
      </TouchableOpacity>
    </PopUp>
  );
};

export default Addpayment;
