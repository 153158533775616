import React from "react";
import {
  ActivityIndicator,
  View,
  StyleSheet,
  ActivityIndicatorProps,
} from "react-native";

import { Colors } from "../../storybook";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

interface Props extends ActivityIndicatorProps {}
const Loading = (props: Props) => {
  const {
    style,
    size = "large",
    color = Colors.lightOrange,
    ...otherProps
  } = props;
  return (
    <View style={[styles.container, style]}>
      <ActivityIndicator size={size} color={color} {...otherProps} />
    </View>
  );
};

export default Loading;
