import * as React from "react";
import Svg, { Path, G } from "react-native-svg";

export interface Props {
  width?: number;
  height?: number;
  color?: any;
}

const myWayLogo = (props: Props) => {
  const width = props.width || 100;
  const height = props.height || 100;
  return (
    <Svg viewBox="0 0 354.06668 496.06668" width={width} height={height}>
      <G fill={props.color}>
        <Path d="M0 242.04667635h47.27453215V.0026824H0v242.04399395M47.28959882.0026824l82.4582646 242.04399395h47.27479882L94.56399764.0026824H47.28959882M177.0413289 242.04667635h47.2746655V.0026824h-47.2746655v242.04399395M271.60532654.0026824H224.3319944l82.45733127 242.04399395h47.27466549L271.60532655.0026824M0 254.02267605h47.27453215V496.06667H0V254.02267605M47.28959882 496.06667l82.4582646-242.04399395h47.27479882L94.56399764 496.06667H47.28959882M177.0413289 254.02267605h47.2746655V496.06667h-47.2746655V254.02267605M271.60532654 496.06667H224.3319944l82.45733127-242.04399395h47.27466549L271.60532655 496.06667" />
      </G>
    </Svg>
  );
};

export default myWayLogo;
