import { observer } from "mobx-react";
import React from "react";
import { Trans } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { Page } from "../../../components";
import { withAuthentication } from "../../../stores";
import { Margins, Radius, Text, Title } from "../../../storybook";

const styles = StyleSheet.create({
  content: {
    padding: Margins.large,
  },
  title: {
    marginBottom: Margins.small,
  },
  button: {
    marginTop: Margins.regular,
  },
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
});

const Email = () => {
  return (
    <Page>
      <View style={styles.content}>
        <Title level={1} style={styles.title}>
          <Trans>Email link sent</Trans>
        </Title>
        <Text>
          <Trans>An email has been sent to your inbox</Trans>.{" "}
          <Trans>
            Open the email on your phone and tap the link to sign-in
          </Trans>
          .
        </Text>
      </View>
    </Page>
  );
};

export default withAuthentication(observer(Email));
