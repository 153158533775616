import React from "react";
import { Trans } from "react-i18next";
import { StyleSheet } from "react-native";

import { displayCalendarDate } from "../../helpers";
import { AvailabilityStatus, Spot } from "../../stores";
import { Colors, Margins, Title } from "../../storybook";

const styles = StyleSheet.create({
  container: {
    textAlign: "center",
    marginBottom: Margins.small,
  },
  icon: {
    marginRight: 8,
  },
  warning: {
    color: Colors.lightOrange,
  },
  error: {
    color: Colors.darkRed,
  },
});

interface Props {
  availability?: AvailabilityStatus;
  spot?: Spot;
}
const Closing = (props: Props) => {
  const { availability, spot } = props;

  if (!availability || availability.open) return null;

  const isClosed =
    availability.startAt.getTime() === availability.endAt.getTime();
  const time = isClosed
    ? spot?.openingToday
    : displayCalendarDate(availability.endAt);

  return (
    <Title style={[styles.container, isClosed ? styles.error : styles.warning]}>
      {isClosed ? (
        <Trans>
          This location is currently closed. The location opens at {{ time }}.
        </Trans>
      ) : (
        <>
          <Trans>This location closes </Trans>
          <Trans>{{ time }}.</Trans>
        </>
      )}
    </Title>
  );
};

export default Closing;
