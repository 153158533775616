import { RouteProp } from "@react-navigation/core";
import { observer } from "mobx-react";
import React, { useMemo, useState, ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Linking, StyleSheet, View } from "react-native";

import { Page } from "../../components";
import { reportError, translatedError } from "../../helpers";
import { BookingStackParamList } from "../../routes/booking";
import { Booking, useStore, withRegistration } from "../../stores";
import {
  Button,
  LicensePlate,
  ListItem,
  Margins,
  Title,
} from "../../storybook";

type PayRouteProp = RouteProp<BookingStackParamList, "BookingAccessConfirm">;

interface Props {
  route: PayRouteProp;
}

const BookingAccessConfirm = (props: Props) => {
  const { route } = props;
  const { bookingId } = route.params || {};
  const store = useStore();
  const { t } = useTranslation();

  const booking = useMemo(
    () => new Booking(`bookings/${bookingId}`, {}, store),
    [bookingId]
  );
  const spot = useMemo(() => booking.spot, [booking.isLoaded]);

  const [error, setError] = useState<{ exit?: ReactNode; access?: ReactNode }>(
    {}
  );
  const [loading, setLoading] = useState<{ exit?: boolean; access?: boolean }>(
    {}
  );

  if (!booking.isLoaded || !spot?.isLoaded) return null;

  const openAccess = async () => {
    try {
      setLoading({ access: true });
      await booking.openGate("access");
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      reportError(err as Error, {
        name: "public/access/confirm",
        type: "web",
        args: {
          action: "openAccess",
          bookingId: booking.id,
        },
      });
      setError({ access: translatedError(message) });
    } finally {
      setLoading({});
    }
  };

  const contactSupport = async () => {
    const canOpen = await Linking.canOpenURL("tel:0031207762939");
    if (canOpen) {
      await Linking.openURL(`tel:0031207762939`);
    }
  };

  return (
    <Page spot={spot}>
      <View style={styles.content}>
        <Title level={2}>
          <Trans>Confirmed, found your booking</Trans>
        </Title>
        <View style={styles.row}>
          <View style={styles.col}>
            <ListItem
              title={t("License plate")}
              description={
                <LicensePlate
                  country={booking.vehicle?.country}
                  code={
                    booking.vehicle?.licensePlate || booking.vehicle?.licenseRef
                  }
                />
              }
            />
          </View>
          <View style={styles.col}>
            <ListItem
              title={t("Found vehicle")}
              description={booking.vehicle?.description || t("Unknown vehicle")}
            />
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <ListItem
              title={t("Start time")}
              description={`${booking.displayStartDate}\n${booking.displayStartTime}`}
            />
          </View>
          <View style={styles.col}>
            <ListItem
              title={t("End time")}
              description={`${booking.displayEndDate}\n${booking.displayEndTime}`}
            />
          </View>
        </View>
        <Button
          title={
            error.access
              ? error.access
              : booking.accessTimes === 2
              ? t("Retry")
              : booking.accessTimes > 3
              ? t("Contact support")
              : t("Open pedestrian gate")
          }
          loading={loading.access}
          disabled={loading.access || !booking.canOpenAccess}
          type={booking.accessTimes > 3 ? "orange" : undefined}
          left={booking.accessTimes > 3 ? "support" : "doorOpen"}
          onPress={booking.accessTimes > 3 ? contactSupport : openAccess}
        />
        <Button
          title={t("Contact support")}
          type="outline"
          left="support"
          onPress={contactSupport}
        />
      </View>
    </Page>
  );
};

export default withRegistration(observer(BookingAccessConfirm));

const styles = StyleSheet.create({
  content: {
    gap: Margins.small,
    paddingBottom: Margins.extraLarge,
  },
  row: {
    flexDirection: "row",
    marginVertical: Margins.small,
    gap: Margins.regular,
  },
  col: {
    flex: 1,
  },
});
