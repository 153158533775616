import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { Page } from "../../components";
import { RootStackParamList } from "../../routes";
import { useStore } from "../../stores";
import { Button, Margins, Text, Title } from "../../storybook";

const styles = StyleSheet.create({
  container: {
    padding: Margins.large,
  },
  title: {
    marginBottom: Margins.large,
  },
  button: {
    marginVertical: Margins.regular,
  },
});

type AdminFailedNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Admin"
>;

interface Props {
  navigation: AdminFailedNavigationProp;
}

const Failed = (props: Props) => {
  const { navigation } = props;
  const { t } = useTranslation();
  const { auth } = useStore();
  const { isAdmin } = auth;

  useEffect(() => {
    if (isAdmin) {
      if (Platform.OS === "web") {
        window.location.href = "/admin";
      } else {
        navigation.replace("Admin", { screen: "AdminStart" });
      }
    }
  }, [isAdmin]);

  const signIn = async () => {
    await auth.signOut();
    if (Platform.OS === "web") {
      window.location.href = "/signin?next=/admin";
    } else {
      navigation.navigate("Auth", {
        screen: "AuthSignIn",
        params: { next: "/admin" },
      });
    }
  };

  return (
    <Page>
      <View style={styles.container}>
        <Title level={1} style={styles.title}>
          <Trans>My Way Admin</Trans>
        </Title>
        <Text>
          <Trans>You are not authorized to access this page.</Trans>
        </Text>
        <Button
          title={t("Sign in with other user")}
          style={styles.button}
          onPress={signIn}
        />
      </View>
    </Page>
  );
};

export default observer(Failed);
