import { Document } from "firestorter";
import { DocumentSource, IDocumentOptions } from "firestorter";

import Store from "../index";

export type Source = DocumentSource;
export type Options = IDocumentOptions;

class StoreDocument<T extends object> extends Document<T> {
  readonly store: Store;

  constructor(source: Source, options: Options, store: Store) {
    super(source, options);
    this.store = store;
  }
}

export default StoreDocument;
