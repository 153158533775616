import {
  format as formatDate,
  getYear,
  getMonth,
  getDate,
  getHours,
  getMinutes,
} from "date-fns";
import React, { ReactNode, useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import Calendar from "./calendar";
import InputView, { Props as InputProps } from "./input";
import TimeInput, { Time } from "./time";
import { Margins } from "../../constant";
import { Button } from "../button";
import { Icon } from "../icon";
import { Alert } from "../modal";
import Text from "../typography/text";

const styles = StyleSheet.create({
  container: {},
  content: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  done: {
    marginHorizontal: Margins.regular,
    marginBottom: Margins.regular,
  },
});

export interface Props extends Omit<InputProps, "renderContent"> {
  value?: Date;
  defaultValue?: Date;
  time?: boolean;
  format?: string;
  onChange?: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  icon?: ReactNode;
}
const InputDate = (props: Props) => {
  const [showPicker, setShowPicker] = useState(false);
  const {
    label,
    borderColor,
    state,
    addon,
    style,
    contentStyle,
    disabled,
    value,
    defaultValue,
    time,
    format,
    onChange,
    minDate,
    maxDate,
    icon,
    ...otherProps
  } = props;
  const [date, setDate] = useState(value || defaultValue);
  const text = date
    ? formatDate(date, format || time !== false ? "PPp" : "PP").replace(
        `, ${formatDate(new Date(), "yyyy")}`,
        ""
      )
    : "";

  useEffect(() => {
    value && setDate(value);
  }, [value]);

  const updateDate = (day: Date) => {
    const newDate = new Date(
      getYear(day),
      getMonth(day),
      getDate(day),
      date ? getHours(date) : 0,
      date ? getMinutes(date) : 0
    );
    setDate(newDate);
    onChange && onChange(newDate);
    time === false && setShowPicker(false);
  };

  const updateTime = (time: Time) => {
    const newDate = new Date(
      getYear(date || new Date()),
      getMonth(date || new Date()),
      getDate(date || new Date()),
      time?.hours || 0,
      time?.minutes || 0
    );
    setDate(newDate);
    onChange && onChange(newDate);
  };

  return (
    <>
      <InputView
        {...{
          label,
          borderColor,
          state,
          addon: { ...addon, after: addon?.after || false },
          style,
          contentStyle,
        }}
        style={[styles.container, style]}
        renderContent={(contentProps) => {
          const { style, textStyle, iconColor } = contentProps;
          return (
            <TouchableOpacity
              style={[styles.content, style]}
              disabled={disabled}
              {...otherProps}
              onPress={() => setShowPicker(true)}
            >
              <Text level={4} style={textStyle}>
                {text}
              </Text>
              {icon || <Icon name="calendar" color={iconColor} />}
            </TouchableOpacity>
          );
        }}
      />
      {showPicker && (
        <Alert onRequestClose={() => setShowPicker(false)}>
          <View>
            <Calendar
              value={date}
              onChange={updateDate}
              minDate={minDate}
              maxDate={maxDate}
            />
            {time !== false && (
              <TimeInput
                onChange={updateTime}
                value={{
                  hours: getHours(date || new Date()),
                  minutes: getMinutes(date || new Date()),
                }}
                date={date}
              />
            )}
            {time !== false && (
              <Button
                title={"Done"}
                onPress={() => setShowPicker(false)}
                style={styles.done}
                disabled={!date}
              />
            )}
          </View>
        </Alert>
      )}
    </>
  );
};

export default InputDate;
