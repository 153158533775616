import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { Loading, Page } from "../../components";
import { BookingStackParamList } from "../../routes/booking";
import { useStore, withRegistration } from "../../stores";
import { Button, Margins, Text, Title } from "../../storybook";

const styles = StyleSheet.create({
  container: {
    padding: Margins.large,
  },
  text: {
    marginVertical: Margins.large,
  },
});

type BookingShareNavigationProp = StackNavigationProp<
  BookingStackParamList,
  "BookingShare"
>;
type BookingShareRouteProp = RouteProp<BookingStackParamList, "BookingShare">;

interface Props {
  navigation: BookingShareNavigationProp;
  route: BookingShareRouteProp;
}
const BookingShare = (props: Props) => {
  const { route, navigation } = props;
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { parking } = useStore();
  const { t } = useTranslation();
  const { shareId } = route.params || {};

  const claim = async () => {
    try {
      setLoading(true);
      const bookingId = await parking.claimShare(shareId);
      navigation.push("BookingSharedDetail", { bookingId });
    } catch (error) {
      const { message } = error as Error;
      console.debug("Share failed: ", message);
      setError(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    claim();
  }, []);

  if (loading) return <Loading />;

  if (error) {
    return (
      <Page>
        <View style={styles.container}>
          <Title level={1}>
            <Trans>Booking share failed</Trans>
          </Title>
          <Text style={styles.text}>
            <Trans>
              Something went wrong with sharing this booking. Please try again.
              If you're having problems please contact the helpdesk
            </Trans>
          </Text>
          <Button title={t("Try again")} onPress={claim} />
        </View>
      </Page>
    );
  }

  return <View style={styles.container} />;
};

export default withRegistration(BookingShare);
