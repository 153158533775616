import React from "react";
import RawCalendar from "react-calendar";
import { View, StyleSheet } from "react-native";
import "react-calendar/dist/Calendar.css";

import { useStore } from "../../../stores";
import { Colors, Margins } from "../../constant";
import { Icon } from "../icon";
import "./calendar.css";

const styles = StyleSheet.create({
  container: {
    padding: Margins.small,
  },
});

interface Props {
  value?: Date | null | undefined | [Date | null, Date | null];
  onChange?: (value: Date) => void;
  range?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const CalendarView = (props: Props) => {
  const { range, ...otherProps } = props;
  const { i18n } = useStore();

  return (
    <View style={styles.container}>
      {/* @ts-ignore */}
      <RawCalendar
        {...otherProps}
        locale={i18n.locale}
        prev2Label={null}
        next2Label={null}
        prevLabel={<Icon name="left" color={Colors.sky} />}
        nextLabel={<Icon name="right" color={Colors.sky} />}
      />
    </View>
  );
};

export default CalendarView;
