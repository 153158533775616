import React from "react";
import { Trans } from "react-i18next";

export const translatedError = (message: string) => {
  switch (message) {
    case "Spot is no longer available":
      return <Trans>Spot is no longer available</Trans>;
    case "Booking status don't allow to open entrance gate":
      return <Trans>Booking status don't allow to open entrance gate</Trans>;
    case "Booking status don't allow to open exit gate":
      return <Trans>Booking status don't allow to open exit gate</Trans>;
    case "Booking status don't allow to open access gate":
      return <Trans>Booking status don't allow to open pedestrian gate</Trans>;
    case "Booking already exist for this vehicle":
      return <Trans>Booking already exist for this vehicle</Trans>;
    case "No booking found for this vehicle":
      return <Trans>No booking found for this vehicle</Trans>;
    case "You have open payments":
      return <Trans>You have open payments</Trans>;
    default:
      return <Trans>Something went wrong, please try again</Trans>;
  }
};
