import React, { FunctionComponent } from "react";
import { ModalProps, StyleProp, ViewStyle } from "react-native";
import { useMediaQuery } from "react-responsive";

import Action from "./action";
import Alert from "./alert";

export interface Props extends ModalProps {
  alert?: {
    contentStyle?: StyleProp<ViewStyle>;
  };
  action?: {
    contentStyle?: StyleProp<ViewStyle>;
  };
  top?: boolean; // Add an optional top prop
}
const Popup: FunctionComponent<Props> = (props) => {
  const { alert, action, top, ...otherProps } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? (
    <Action {...otherProps} {...action} />
  ) : (
    <Alert top={top} {...otherProps} {...alert} />
  );
};

export default Popup;
