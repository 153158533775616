import React from "react";
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  View,
  ViewProps,
} from "react-native";

import { Margins, Text } from "../../../storybook";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: Margins.regular,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  image: {
    width: 40,
    height: 40,
    resizeMode: "contain",
  },
});

interface Props extends ViewProps {
  title: string;
  image?: ImageSourcePropType;
}
const Option = (props: Props) => {
  const { title, image, style, ...otherProps } = props;

  return (
    <View style={[styles.container, style]} {...otherProps}>
      <Text>{title}</Text>
      <Image source={image} style={styles.image} />
    </View>
  );
};

export default Option;
