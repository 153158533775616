import { useMemo } from "react";

import { Spot } from "../../../stores";

const useSpotAvailability = (spot: Spot) => {
  const availability = useMemo(
    () =>
      spot.isLoaded
        ? spot.getAvailabilityStatus({
            startAt: new Date(),
            endAt: new Date(Date.now() + 24 * 60 * 60 * 1000),
          })
        : undefined,
    [spot.isLoaded]
  );

  const closingAvailability = useMemo(
    () =>
      spot.isLoaded
        ? spot.getAvailabilityStatus({
            startAt: new Date(),
            endAt: new Date(Date.now() + 24 * 60 * 60 * 1000),
            parkWhenClosed: false,
          })
        : undefined,
    [spot.isLoaded]
  );

  return { availability, closingAvailability };
};

export default useSpotAvailability;
