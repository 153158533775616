import { RouteProp, useLinkTo } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useState, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { Page } from "../../../components";
import { AuthStackParamList } from "../../../routes/auth";
import { Spot, useStore, withAuthentication } from "../../../stores";
import {
  Button,
  Margins,
  Radius,
  Text,
  TextInput,
  Title,
} from "../../../storybook";

const styles = StyleSheet.create({
  content: {
    padding: Margins.large,
  },
  title: {
    marginBottom: Margins.small,
  },
  button: {
    marginTop: Margins.regular,
  },
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
});

type EmailNavigationProp = StackNavigationProp<
  AuthStackParamList,
  "AuthEmailConfirm"
>;
type EmailRouteProp = RouteProp<AuthStackParamList, "AuthEmailConfirm">;

interface Props {
  navigation: EmailNavigationProp;
  route: EmailRouteProp;
}
const Email = (props: Props) => {
  const { route } = props;
  const { next, spotId } = route.params || {};
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const linkEmail = window.localStorage.getItem("email");
  const store = useStore();
  const { auth } = store;
  const linkTo = useLinkTo();
  const { t } = useTranslation();

  const spot = useMemo(
    () => spotId && new Spot(`spots/${spotId}`, {}, store),
    [spotId]
  );

  useEffect(() => {
    if (linkEmail) {
      verifyLink(linkEmail);
    }
  }, []);

  const verifyLink = async (email: string) => {
    try {
      await auth.verifySignInLink({ email, link: window.location.href });
      if (Platform.OS === "web") {
        window.location.href = next || "/";
      } else {
        linkTo(next || "/");
      }
    } catch (error) {
      // TODO show error
      console.error("Error: ", error);
      setError(true);
    }
  };

  return (
    <Page spot={typeof spot === "string" ? undefined : spot}>
      <View style={styles.content}>
        <Title level={1} style={styles.title}>
          {error ? (
            <Trans>Sign in failed</Trans>
          ) : (
            <Trans>Email verification</Trans>
          )}
        </Title>
        {error ? (
          <Text>
            <Trans>
              Something went wrong verifying your sign in, please try again
            </Trans>
            ...
          </Text>
        ) : linkEmail ? (
          <Text>
            <Trans>We are verifying your e-mail</Trans>...
          </Text>
        ) : (
          <>
            <TextInput
              label={t("Email")}
              onChangeText={setEmail}
              value={email}
            />
            <Button
              style={styles.button}
              title={t("Next")}
              type="orange"
              right="arrowright"
              onPress={() => verifyLink(email)}
              loading={auth.verifySignInLink.inProgress}
              disabled={auth.verifySignInLink.inProgress}
            />
          </>
        )}
      </View>
    </Page>
  );
};

export default withAuthentication(observer(Email));
