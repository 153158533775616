import React, { FunctionComponent, ReactNode } from "react";
import { Platform, StyleSheet, View, ViewProps } from "react-native";

import Footer from "./footer";
import { Props as HeaderProps } from "./header";
import Main from "./main";

const styles = StyleSheet.create({
  container: {
    // @ts-ignore
    minHeight: "100vh",
  },
  content: {
    width: "100%",
  },
});

// @ts-ignore
export interface Props extends ViewProps, HeaderProps {
  footer?: ReactNode;
}
const ContainerPage: FunctionComponent<Props> = (props) => {
  const {
    style,
    language,
    availableLanguages,
    onChangeLanguage,
    footer,
    children,
    ...otherProps
  } = props;

  const renderContent = () => {
    return (
      <>
        <View>
          <Main>
            {children}
            {footer && <Footer>{footer}</Footer>}
          </Main>
        </View>
      </>
    );
  };

  return (
    <View style={[styles.container, style]} {...otherProps}>
      {Platform.OS === "web" ? (
        <View style={styles.content}>{renderContent()}</View>
      ) : (
        renderContent()
      )}
    </View>
  );
};

export default ContainerPage;
